import { Dispatch, FC, SetStateAction, useEffect, useRef } from 'react';

import { Headline } from '@lichtblick/ui-components/atoms/Headline';
import { Text } from '@lichtblick/ui-components/atoms/Text';

import {
  StyledMRoadToZeroItem,
  StyledMRoadToZeroContent,
  StyledMRoadToZeroItemWrapper,
} from './MRoadToZeroItem.styles';

import { assertExhaustive } from '../../helpers/assertExhaustive';
import { MRoadToZeroItemType } from '../../types';
import { colorMap } from '../../utils/colors';
import { AButton } from '../AButton/AButton';
import { AImage } from '../AImage/AImage';
import { AVideo } from '../AVideo/AVideo';
import { MAnimatedIllustration } from '../MAnimatedIllustration/MAnimatedIllustration';

const MediaContent: FC<{ content: MRoadToZeroItemType['media'] }> = ({ content: contentList }) => {
  if (!contentList?.[0]) {
    return null;
  }

  const content = contentList[0];

  switch (content.component) {
    case 'a-image':
      return <AImage {...content} />;
    case 'a-video':
      return <AVideo {...content} />;
    case 'm-animated-illustration':
      return <MAnimatedIllustration {...content} />;
    default:
      assertExhaustive(content);
  }
};

export const MRoadToZeroItem: FC<MRoadToZeroItemType & { setProgressValue: Dispatch<SetStateAction<number>> }> = ({
  alignedLeft = false,
  anchorId,
  buttonLink,
  buttonSolid,
  headline,
  media,
  progressValue,
  setProgressValue,
  text,
  theme,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver(
      ([entry]) => entry.intersectionRatio >= 0.5 && setProgressValue(Number(progressValue)),
      {
        threshold: 0.5,
      },
    );

    if (ref.current) {
      intersectionObserver.observe(ref.current);
    }

    return () => {
      intersectionObserver.disconnect();
    };
  }, [setProgressValue, ref, progressValue]);

  return (
    <StyledMRoadToZeroItem $background={colorMap[theme]} id={anchorId} ref={ref}>
      <StyledMRoadToZeroItemWrapper $reversed={alignedLeft}>
        <StyledMRoadToZeroContent>
          <Headline>{headline}</Headline>
          <Text>{text}</Text>
          {buttonLink?.length === 1 && <AButton {...buttonLink[0]} variant="link" />}
          {buttonSolid?.length === 1 && <AButton {...buttonSolid[0]} />}
        </StyledMRoadToZeroContent>
        <MediaContent content={media} />
      </StyledMRoadToZeroItemWrapper>
    </StyledMRoadToZeroItem>
  );
};

import { FC } from 'react';

type OWallboxConfigurationProps = {
  configId: string;
  id: string;
};

export const OWallboxConfiguration: FC<OWallboxConfigurationProps> = ({ configId, id }) => {
  const configData = {
    contents: { products: `/app-content/${id}.json` },
    graphql: {
      graphql_server: process.env.NEXT_PUBLIC_GRAPHQL_SERVER,
    },
  };

  return (
    <div id={configId} style={{ display: 'none' }}>
      {JSON.stringify(configData)}
    </div>
  );
};

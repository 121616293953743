import { FC, ReactNode, useCallback, useMemo, useState } from 'react';

import { DownloadIconS } from '@lichtblick/icons/svg/download/download-s';
import { DownloadIconXs } from '@lichtblick/icons/svg/download/download-xs';
import { InfoIconS } from '@lichtblick/icons/svg/info/info-s';
import { InfoIconXs } from '@lichtblick/icons/svg/info/info-xs';
import { InvoiceIconS } from '@lichtblick/icons/svg/invoice/invoice-s';
import { InvoiceIconXs } from '@lichtblick/icons/svg/invoice/invoice-xs';
import { PhoneIconS } from '@lichtblick/icons/svg/phone/phone-s';
import { PhoneIconXs } from '@lichtblick/icons/svg/phone/phone-xs';
import { useTracker } from '@lichtblick/tracker';
import { BoxCTA, BoxCTAProps, LegacyButtonProps, TextCTA } from '@lichtblick/ui-components';

import { getHref } from '../../helpers/links';
import { AButtonType } from '../../types/storyblok';
import { OInfoDialog } from '../OInfoDialog';

export const contentfulToButtonProps = (button: AButtonType, options?: LegacyButtonProps): LegacyButtonProps => ({
  children: button.text,
  href: getHref(button),
  isDownloadLink: Boolean(button.isDownloadLink),
  shouldOpenInNewTab: Boolean(button.openInNewTab),
  ...options,
});

export type AButtonProps = AButtonType & {
  hasNoIcon?: boolean;
  isSlim?: BoxCTAProps['isSlim'];
  isWide?: BoxCTAProps['isWide'];
  tabIndex?: number;
  variant?: BoxCTAProps['variant'] | 'link';
};

export const AButton: FC<AButtonProps> = ({
  hasNoIcon,
  isDownloadLink,
  isSlim,
  isWide,
  linkAsset,
  linkDialog,
  linkEntry,
  linkUrl,
  openAsPhoneLink: isPhoneLink,
  openInNewTab: shouldOpenInNewTab,
  tabIndex,
  text,
  trackingOption,
  variant,
}) => {
  let button: ReactNode = undefined;
  const { trackCustomEvent } = useTracker();
  const hasInfoDialog = linkDialog?.length === 1;
  const [isInfoDialogOpen, setIsInfoDialogOpen] = useState(false);
  const target = shouldOpenInNewTab || Boolean(linkAsset?.filename) ? '_blank' : undefined;

  const href = !linkAsset?.filename
    ? linkEntry?.cached_url
      ? `/${linkEntry?.cached_url}${linkUrl ? linkUrl : ''}`
      : linkUrl
    : linkAsset.filename;

  const icon = useMemo(() => {
    if (hasNoIcon) {
      return;
    }

    if (hasInfoDialog) {
      return isSlim ? <InfoIconXs /> : <InfoIconS />;
    } else if (isPhoneLink) {
      return isSlim ? <PhoneIconXs /> : <PhoneIconS />;
    } else if (isDownloadLink && shouldOpenInNewTab) {
      return isSlim ? <InvoiceIconXs /> : <InvoiceIconS />;
    } else if (isDownloadLink) {
      return isSlim ? <DownloadIconXs /> : <DownloadIconS />;
    }
  }, [hasInfoDialog, hasNoIcon, isDownloadLink, isPhoneLink, isSlim, shouldOpenInNewTab]);

  const handleEvent = useCallback(() => {
    if (hasInfoDialog) {
      setIsInfoDialogOpen(true);
    }

    if (trackingOption?.[0] && trackingOption[0].eventName) {
      trackCustomEvent({
        eventName: trackingOption[0].eventName,
        customProps: trackingOption[0].customProps && JSON.parse(trackingOption[0].customProps),
      });
    }
  }, [hasInfoDialog, trackCustomEvent, trackingOption]);

  if (variant === 'link' || isPhoneLink || isDownloadLink) {
    button =
      href && !hasInfoDialog ? (
        <TextCTA
          as="a"
          href={href}
          icon={icon}
          isDownload={!!isDownloadLink}
          isSlim={isSlim}
          onClick={handleEvent}
          tabIndex={tabIndex}
          target={target}
        >
          {text}
        </TextCTA>
      ) : (
        <TextCTA as="button" icon={icon} isSlim={isSlim} onClick={handleEvent} tabIndex={tabIndex}>
          {text}
        </TextCTA>
      );
  } else {
    button =
      href && !hasInfoDialog ? (
        <BoxCTA
          as="a"
          href={href}
          icon={icon}
          isDownload={!!isDownloadLink}
          isSlim={isSlim}
          isWide={isWide}
          onClick={handleEvent}
          tabIndex={tabIndex}
          target={target}
          variant={variant}
        >
          {text}
        </BoxCTA>
      ) : (
        <BoxCTA
          as="button"
          icon={icon}
          isSlim={isSlim}
          isWide={isWide}
          onClick={handleEvent}
          tabIndex={tabIndex}
          variant={variant}
        >
          {text}
        </BoxCTA>
      );
  }

  return (
    <>
      {button}
      {hasInfoDialog && (
        <OInfoDialog
          {...linkDialog[0]}
          isOpen={isInfoDialogOpen}
          onSecondaryButtonClick={() => setIsInfoDialogOpen(false)}
        />
      )}
    </>
  );
};

import { FC, PropsWithChildren } from 'react';

import { Items, Item } from '@lichtblick/contentful/components/OLogos/OLogos.styles';
import { CheckIconXs } from '@lichtblick/icons/svg/check/check-xs';
import { Colors, themeColors } from '@lichtblick/theme';

import {
  Root,
  StageContainer,
  ContentContainer,
  TextContainer,
  StyledListItem as StyledListItemImpl,
  H1Container,
  ImageContainer,
  OverlappingSection,
  OverlappingContainer,
} from './SolarStage.styles';

import { ElementMappers, RichText } from '../../../helpers/RichText';
import { SolarStageType } from '../../../types';
import { getColor } from '../../../utils';
import { AImage } from '../../AImage/AImage';
import { StyledMList } from '../../MList/MList.styles';
import { MLogoItem } from '../../MLogoItem/MLogoItem';
import { MTextIntro } from '../../MTextIntro';
import { SoftFunnelEntryCard } from '../SoftFunnelEntry';

const StyledListItem: FC<PropsWithChildren> = ({ children }) => (
  <StyledListItemImpl>
    <CheckIconXs />
    {children}
  </StyledListItemImpl>
);

const elementMappers: Partial<ElementMappers> = {
  ul: StyledMList,
  li: StyledListItem,
};

export const SolarStage: FC<SolarStageType> = ({
  followingSectionTheme,
  logos,
  media,
  sellingPoints,
  textIntro,
  theme,
}) => {
  const mediaItem = media?.[0]?.component === 'a-image' ? { ...media[0], imageDessktop: undefined } : undefined;
  const backgroundColor = getColor(theme) || Colors.Moss;
  const foregroundColor = themeColors(backgroundColor).primary;

  return (
    <Root data-testid="solar-stage">
      <StageContainer>
        <ContentContainer $backgroundColor={backgroundColor} $textColor={foregroundColor}>
          <TextContainer>
            {logos?.length && (
              <Items $isVerticallyCentered={false}>
                {logos.map((item) => (
                  <Item $length={logos.length} key={item._uid}>
                    <MLogoItem {...item} />
                  </Item>
                ))}
              </Items>
            )}
            <H1Container>
              {textIntro?.[0] && (
                <MTextIntro
                  {...textIntro[0]}
                  accentColor={foregroundColor}
                  button={undefined}
                  headlineProps={{
                    size: 'L',
                    renderAs: 'h1',
                  }}
                  textProps={{
                    renderAs: 'div',
                    align: 'left',
                  }}
                />
              )}
              {sellingPoints && <RichText elementMappers={elementMappers} richText={sellingPoints} />}
            </H1Container>
          </TextContainer>
        </ContentContainer>
        {mediaItem && (
          <ImageContainer data-image-container>
            {mediaItem ? <AImage {...mediaItem} isBackground loading="eager" maxWidth={1920} /> : null}
          </ImageContainer>
        )}
      </StageContainer>
      <OverlappingSection $backgroundColor={getColor(followingSectionTheme)}>
        <OverlappingContainer>
          <SoftFunnelEntryCard theme="faded-gray" />
        </OverlappingContainer>
      </OverlappingSection>
    </Root>
  );
};

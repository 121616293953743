import classNames from 'classnames';
import { FC } from 'react';

import { Breakpoints } from '@lichtblick/theme';
import { Text } from '@lichtblick/ui-components';

import styles from './MediaFigure.module.scss';

import { assertExhaustive } from '../../helpers/assertExhaustive';
import { OMediaType } from '../../types/storyblok';
import { AImage } from '../AImage/AImage';
import { AVideo } from '../AVideo/AVideo';
import { MAnimatedIllustration } from '../MAnimatedIllustration/MAnimatedIllustration';
import { MIframe } from '../MIframe/MIframe';
import { MYoutube } from '../MYoutube/MYoutube';

export type MediaFigureProps = Pick<OMediaType, 'media' | 'layout'> & {
  caption?: string | null;
  copyright?: string | null;
  index?: number;
};

const getMediaMaxWidth = (layout?: string | null) => {
  switch (layout) {
    case 'large':
      return Breakpoints.Max;
    case 'medium':
      return Breakpoints.Large;
    default:
      return;
  }
};

const MediaContent: FC<{ content: MediaFigureProps['media']; index?: number; layout: MediaFigureProps['layout'] }> = ({
  content: contentList,
  index,
  layout,
}) => {
  if (!contentList?.length) {
    return null;
  }

  const content = contentList[0];

  switch (content.component) {
    case 'a-image':
      return <AImage {...content} loading={index === 0 ? 'eager' : undefined} maxWidth={getMediaMaxWidth(layout)} />;
    case 'a-video':
      return <AVideo {...content} />;
    case 'm-animated-illustration':
      return <MAnimatedIllustration {...content} />;
    case 'm-iframe':
      return <MIframe {...content} />;
    case 'm-youtube':
      return <MYoutube {...content} />;
    default:
      assertExhaustive(content);
  }
};

export const MediaFigure: FC<MediaFigureProps> = ({ caption, copyright, index, layout, media }) => (
  <figure>
    <MediaContent content={media} index={index} layout={layout} />
    {(caption || copyright) && (
      <figcaption className={classNames(styles.figcaption, layout === 'large' && styles['figcaption-large'])}>
        {caption && <Text>{caption}</Text>}
        {copyright && <Text size="Xs">{copyright}</Text>}
      </figcaption>
    )}
  </figure>
);

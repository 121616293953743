import styled from '@lichtblick/styled';
import { Breakpoints, Colors, MediaMediumAndAbove, MediaSmallAndAbove, Spacing } from '@lichtblick/theme';
import { Container } from '@lichtblick/ui-components';
import { StyledHeadline } from '@lichtblick/ui-components/atoms/Headline/Headline.styles';

import { Items as LogoItems, Item as LogoItem } from '../../OLogos/OLogos.styles';
import { StageCard } from '../SoftFunnelEntry';

export const Root = styled.section`
  display: flex;
  flex-direction: column;
`;

export const StageContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  ${MediaMediumAndAbove} {
    /* stylelint-disable-next-line meowtec/no-px */
    height: 600px;
    flex-direction: row;
  }
`;

export const ContentContainer = styled.div<{ $backgroundColor: Colors; $textColor: Colors }>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background: ${({ $backgroundColor }) => $backgroundColor};
  color: ${({ $textColor }) => $textColor};

  ${MediaMediumAndAbove} {
    width: 50%;
    justify-content: flex-end;
  }
`;

export const OverlappingSection = styled.section<{ $backgroundColor?: Colors }>`
  background: ${({ $backgroundColor }) => $backgroundColor || Colors.FadedGray};
`;

export const OverlappingContainer = styled(Container)`
  margin-bottom: -4.5rem;
  ${StageCard} {
    transform: translateY(-4.5rem);
  }
`;

export const TextContainer = styled.div`
  width: 100%;
  max-width: var(--max-content-width, ${Breakpoints.Large}px);
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: ${Spacing.M};
  padding: 2rem;
  padding-inline: ${Spacing.Xs};
  padding-bottom: 8rem;

  ${MediaMediumAndAbove} {
    max-width: calc(var(--max-content-width, ${Breakpoints.Large}px) / 2);
    padding-inline: ${Spacing.Xl};
    padding-top: 4rem;
    justify-content: space-between;
  }

  ${LogoItems} {
    display: none;
    justify-content: flex-start;
    flex-wrap: nowrap;

    ${MediaSmallAndAbove} {
      display: flex;
      max-width: 50%;
    }
    ${MediaMediumAndAbove} {
      max-width: 70%;
    }
  }

  ${LogoItem} {
    flex-basis: auto;
    flex-shrink: 1;
  }

  ${StyledHeadline} {
    /* stylelint-disable-next-line meowtec/no-px */
    font-size: 24px;
    ${MediaSmallAndAbove} {
      /* stylelint-disable-next-line meowtec/no-px */
      font-size: 40px;
    }
    ${MediaMediumAndAbove} {
      /* stylelint-disable-next-line meowtec/no-px */
      font-size: 42px;
    }
  }
`;

export const StyledListItem = styled.li`
  list-style: none;
  display: flex;
  align-items: center;
  gap: ${Spacing.Xs};
  width: 100%;
  br {
    display: none;
  }
`;

export const H1Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${Spacing.S};
`;

export const ImageContainer = styled.div`
  width: 100%;
  /* stylelint-disable-next-line meowtec/no-px */
  height: 180px;
  justify-content: center;
  align-items: center;

  ${MediaMediumAndAbove} {
    height: 100%;
    width: 50%;
    display: flex;
  }
`;

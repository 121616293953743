import Link from '@lichtblick/link';
import styled, { FlattenSimpleInterpolation, css } from '@lichtblick/styled';
import { FontWeights, MediaMediumAndAbove, Opacities, Spacing } from '@lichtblick/theme';

import { focusRing, linkUnderline, linkUnderlineHover, linkUnderlineNone, linkUnderlineThin } from '../shared';

export type TextCTAVariant = 'default' | 'plain' | 'thin';

type StyledTextCTAProps = {
  $isInlineLink?: boolean;
  $isSlim?: boolean;
  $variant: TextCTAVariant;
};

export const StyledTextCTAIconWrapper = styled.span`
  position: relative;

  & > * {
    vertical-align: middle;
  }
`;

export const StyledTextCTAText = styled.span`
  ${StyledTextCTAIconWrapper} + & {
    margin-left: ${Spacing.Xxs};
  }
`;

const PlainTextCTAStyles = css`
  > ${StyledTextCTAText} {
    ${linkUnderlineNone}
  }
`;

const ThinTextCTAStyles = css`
  font-weight: ${FontWeights.Regular};

  > ${StyledTextCTAText} {
    ${linkUnderlineThin};
  }

  &:hover > ${StyledTextCTAText} {
    ${linkUnderlineHover};
  }
`;

const TextCTAStyleMap: { [key in TextCTAVariant]?: FlattenSimpleInterpolation } = {
  plain: PlainTextCTAStyles,
  thin: ThinTextCTAStyles,
};

export const StyledTextCTA = styled(Link)<StyledTextCTAProps>`
  appearance: none;
  background-color: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  display: inline-block;
  font: inherit;
  font-size: ${({ $isInlineLink, $isSlim }) => ($isInlineLink ? 'inherit' : $isSlim ? '0.75rem' : '1rem')};
  font-weight: ${FontWeights.Bold};
  line-height: 1.7;
  outline: none;
  word-break: break-word;

  ${MediaMediumAndAbove} {
    font-size: ${({ $isInlineLink, $isSlim }) => ($isInlineLink ? 'inherit' : $isSlim ? '0.875rem' : '1.125rem')};
  }

  & > ${StyledTextCTAText} {
    ${linkUnderline};
    padding: 2px 0 1px;

    ${MediaMediumAndAbove} {
      padding: 2px 0;
    }
  }

  &:hover > ${StyledTextCTAText} {
    ${linkUnderlineHover};
  }

  &:disabled,
  &[disabled] {
    opacity: ${Opacities.DisabledCustomIcon};
    pointer-events: none;

    &::before {
      filter: grayscale(100%);
      transform: translate(0, 0);
    }
  }

  &:focus-visible {
    ${focusRing};
  }

  &:active {
    outline: none;
  }

  &[target='_blank'] ${StyledTextCTAText}::after {
    content: '\\2197\uFE0E';
  }

  ${({ $variant }) => TextCTAStyleMap[$variant]}
`;

import { richTextToHtmlMapper } from '../../helpers/RichText';
import { MAccordionItemType } from '../../types/storyblok';

export const generateStructuredData = (pathname: string, items: MAccordionItemType[]) => {
  const questions =
    items?.map((item) => ({
      '@type': 'Question',
      name: item.title,
      acceptedAnswer: {
        '@type': 'Answer',
        text: richTextToHtmlMapper(item.text),
      },
    })) ?? [];

  return {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    '@id': pathname,
    mainEntity: questions,
  };
};

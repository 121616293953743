import { FC, useContext } from 'react';

import { assertExhaustive } from '../../helpers/assertExhaustive';
import { SettingsContext } from '../../helpers/settingsContext';
import { WithMetaData } from '../../helpers/templateMapper';
import { useTemplateTracker } from '../../hooks';
import { OStageNarrowType, StoryblokStoryType, TPressDetailType } from '../../types/storyblok';
import { Layout } from '../Layout';
import { MNavigationSecondary } from '../MNavigationSecondary';
import { MTeaserListItemProps } from '../MTeaserListItem/MTeaserListItem';
import { ODownload } from '../ODownload/ODownload';
import { OEditorial } from '../OEditorial';
import { OMedia } from '../OMedia/OMedia';
import { OStageNarrow } from '../OStageNarrow';
import { OStandoutText } from '../OStandoutText';
import { OTeaserList } from '../OTeaserList';
import { OText } from '../OText/OText';

export type TPressDetailProps = TPressDetailType & {
  listItems?: MTeaserListItemProps[];
};

const PartialContent: FC<{ content: Exclude<TPressDetailType['partials'], undefined>[number]; index: number }> = ({
  content,
  index,
}) => {
  if (!content) {
    return null;
  }

  switch (content.component) {
    case 'o-media':
      return <OMedia {...content} index={index} />;
    case 'o-text':
      return <OText {...content} />;
    case 'o-download':
      return <ODownload {...content} />;
    default:
      assertExhaustive(content);
  }
};

export const TPressDetail: FC<WithMetaData<StoryblokStoryType<TPressDetailProps>>> = ({
  content: {
    _uid,
    articleTitle,
    author,
    breadcrumbTitle,
    category,
    download,
    listItems,
    metaTitle,
    partials,
    secondaryNavigation,
    secondaryNavigationButtonIntroOnDesktop: isButtonIntroOnDesktop,
    trackingContentGroup,
    trackingContentGroup2,
    trackingContentGroup3,
    trackingPageArea,
  },
  parentPages,
  slug,
  uuid,
}) => {
  const oStageNarrowTextIntro: OStageNarrowType['textIntro'][number] = {
    headline: articleTitle,
    _uid: `o-stage-stage-narrow-text-intro-${_uid}`,
    component: 'm-text-intro',
    chip: category,
  };

  useTemplateTracker({
    contentGroup: trackingContentGroup,
    contentGroup2: trackingContentGroup2,
    contentGroup3: trackingContentGroup3,
    pageArea: trackingPageArea ?? '',
    pageType: 'press-detail',
    pageTitle: metaTitle ?? '',
  });

  const { pressDownloadButtonText, pressDownloadHeadline, pressRelatedArticlesHeadline, pressTeaser } =
    useContext(SettingsContext);

  return (
    <Layout breadcrumbTitle={breadcrumbTitle} key={_uid} parentPages={parentPages} slug={slug}>
      {secondaryNavigation?.length === 1 && (
        <MNavigationSecondary {...secondaryNavigation[0]} isButtonIntroOnDesktop={isButtonIntroOnDesktop} />
      )}
      <OStageNarrow
        _uid={`o-stage-stage-narrow-${_uid}`}
        component="o-stage-narrow"
        key={`o-stage-stage-narrow-${_uid}`}
        textIntro={[oStageNarrowTextIntro]}
        theme="white"
      />
      {partials?.map((item, index) => item && <PartialContent content={item} index={index} key={item._uid} />)}
      {download && (
        <OStandoutText
          _uid={`o-standout-text-${_uid}`}
          button={[
            {
              _uid: `o-standout-text-a-button-${_uid}`,
              component: 'a-button',
              linkUrl: download,
              openAsDownload: true,
              text: pressDownloadButtonText,
            },
          ]}
          component="o-standout-text"
          headline={pressDownloadHeadline}
          key={`o-standout-text-${_uid}`}
          theme="light-gray"
        />
      )}
      {author?.length === 1 && (
        <OEditorial
          _uid={`o-editorial-${_uid}`}
          button={[
            {
              _uid: `o-editorial-a-button-${_uid}`,
              component: 'a-button',
              linkUrl: `mailto:${author?.[0].email}`,
              text: author?.[0].email,
            },
          ]}
          component="o-editorial"
          headline={author?.[0].name ?? ''}
          key={`o-editorial-${_uid}`}
          media={author?.[0].image}
          paddingBottomDesktop="xl"
          paddingBottomMobile="m"
          paddingTopDesktop="xl"
          paddingTopMobile="m"
          tagline={author?.[0].tagline}
          text={author?.[0].description ?? ''}
          theme="white"
        />
      )}
      <OTeaserList
        _uid={`o-teaser-list-${_uid}`}
        component="o-teaser-list"
        contentType="t-press-detail"
        excluded={[uuid]}
        filterByCategory={category}
        listItems={listItems}
        textIntro={[
          {
            _uid: `m-text-intro-${_uid}`,
            component: 'm-text-intro',
            headline: pressRelatedArticlesHeadline ?? '',
          },
        ]}
        theme="white"
      />
      {pressTeaser?.[0] && <OStandoutText {...pressTeaser[0]} />}
    </Layout>
  );
};

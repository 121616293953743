import React from 'react';

import { AlertIconM } from '@lichtblick/icons/svg/alert/alert-m';
import { Colors } from '@lichtblick/theme';
import { Text } from '@lichtblick/ui-components';

import { CalculatorLayout } from './CalculatorLayout';
import { StyledCalculatorMaintenanceContent } from './CalculatorMaintenance.styles';

import { ElementMappers, RichText } from '../../helpers/RichText';
import { MaintenanceTextType } from '../../types';
import { AButton } from '../AButton';

type MaintenanceElementProps = {
  headline?: string;
  id: string;
  isActionTriggerDisabled?: boolean;
  maintenanceText?: MaintenanceTextType;
};

const elementMappers: Partial<ElementMappers> = {
  p: ({ children }) => <Text>{children}</Text>,
};

export const CalculatorMaintenance: React.FC<MaintenanceElementProps> = ({
  headline,
  id,
  isActionTriggerDisabled,
  maintenanceText,
}) => {
  const { button, text } = maintenanceText || {};

  return (
    <CalculatorLayout
      headerColor={Colors.Orange}
      headline={headline}
      icon={<AlertIconM />}
      id={id}
      isActionTriggerDisabled={isActionTriggerDisabled}
    >
      <StyledCalculatorMaintenanceContent>
        {text && <RichText elementMappers={elementMappers} richText={text} />}
        {button?.[0] && <AButton {...button?.[0]} variant="link" />}
      </StyledCalculatorMaintenanceContent>
    </CalculatorLayout>
  );
};

import { FC } from 'react';

import { Icon, toIconName } from '@lichtblick/icons';
import { Colors, Spacing } from '@lichtblick/theme';
import { Box, BoxCTA, Headline } from '@lichtblick/ui-components';

import { GoogleLogo } from './google';
import {
  StyledCTAWrapper,
  StyledGridColumn,
  StyledIcon,
  StyledList,
  StyledListItem,
  StyledLowerBox,
  StyledRatingBox,
  StyledStars,
  StyledText,
} from './OSuccessRating.styles';
import { TrustpilotLogo } from './trustpilot';

import { ElementMappers, RichText } from '../../helpers/RichText';
import { OSuccessRatingType } from '../../types/storyblok';
import { AImage } from '../AImage';
import { GridColumn, GridContainer, GridRow } from '../shared';

const elementMappers: Partial<ElementMappers> = {
  ul: ({ children }) => <StyledList>{children}</StyledList>,
  li: ({ children }) => (
    <StyledListItem>
      <StyledIcon name={toIconName('check-xs')} />
      {children}
    </StyledListItem>
  ),
};

export const OSuccessRating: FC<OSuccessRatingType> = ({
  googleLink,
  ratingActionLine,
  ratingHeadline,
  ratingSubline,
  stageBulletItems,
  stageHeadline,
  stageImage,
  stageSubline,
  trustpilotLink,
}) => (
  <>
    <Box data-testid="o-success-rating" pbd={Spacing.M} pbm={Spacing.Xs} ptd={Spacing.S} ptm={Spacing.Xs}>
      <GridContainer>
        <GridRow $isReversed>
          {stageImage?.[0] && (
            <GridColumn $isAlignedMiddle $width={6}>
              <AImage {...stageImage[0]} />
            </GridColumn>
          )}
          <StyledGridColumn $isAlignedMiddle $width={stageImage ? 6 : 12}>
            <Headline renderAs="h2" size="M">
              {stageHeadline}
            </Headline>
            <StyledText>{stageSubline}</StyledText>
            <RichText elementMappers={elementMappers} richText={stageBulletItems} />
          </StyledGridColumn>
        </GridRow>
      </GridContainer>
    </Box>
    <StyledLowerBox>
      <GridContainer>
        <GridRow>
          <GridColumn $isAlignedMiddle $push={2} $width={8}>
            <StyledStars align="center">
              <Icon name="StarFullIconL" shiftColor={Colors.Orange} />
              <Icon name="StarFullIconL" shiftColor={Colors.Orange} />
              <Icon name="StarFullIconL" shiftColor={Colors.Orange} />
              <Icon name="StarFullIconL" shiftColor={Colors.Orange} />
              <Icon name="StarFullIconL" shiftColor={Colors.Orange} />
            </StyledStars>
            <Headline align="center" renderAs="h2" size="S">
              {ratingHeadline}
            </Headline>
            <StyledText align="center">{ratingSubline}</StyledText>
            <StyledRatingBox>
              <StyledText align="center">{ratingActionLine}</StyledText>
              <StyledCTAWrapper>
                <BoxCTA
                  ariaLabel="Jetzt bewerten auf Google"
                  as="a"
                  href={googleLink}
                  icon={<GoogleLogo />}
                  target="_blank"
                  variant="secondary"
                />
                <BoxCTA
                  ariaLabel="Jetzt bewerten auf Trustpilot"
                  as="a"
                  href={trustpilotLink}
                  icon={<TrustpilotLogo />}
                  target="_blank"
                  variant="secondary"
                />
              </StyledCTAWrapper>
            </StyledRatingBox>
          </GridColumn>
        </GridRow>
      </GridContainer>
    </StyledLowerBox>
  </>
);

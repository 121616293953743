import { FC } from 'react';

import { MNavigationSecondary } from '../../components/MNavigationSecondary';
import { assertExhaustive } from '../../helpers/assertExhaustive';
import { WithMetaData } from '../../helpers/templateMapper';
import { useTemplateTracker } from '../../hooks';
import { StoryblokStoryType, TProductType } from '../../types/storyblok';
import { CasesSlider } from '../eaas/CasesSlider/CasesSlider';
import { CaseStudy } from '../eaas/CaseStudy';
import { FourSteps } from '../eaas/FourSteps';
import { LeadForm } from '../eaas/LeadForm';
import { Promo } from '../eaas/Promo/Promo';
import { SoftFunnelEntry } from '../eaas/SoftFunnelEntry';
import { SolarCheckoutTeaser } from '../eaas/SolarCheckoutTeaser';
import { SolarProductBundle } from '../eaas/SolarProductBundle';
import { SolarSlider } from '../eaas/SolarSlider/SolarSlider';
import { SolarStage } from '../eaas/SolarStage';
import { Layout } from '../Layout';
import { OAccordion } from '../OAccordion';
import { OAllInOneCalculator } from '../OAllInOneCalculator';
import { OCarCalculator } from '../OCarCalculator';
import { OCarousel } from '../OCarousel';
import { OColumns } from '../OColumns';
import { ODownload } from '../ODownload';
import { OEAutoTariffComparison } from '../OEAutoTariffComparison';
import { OEditorial } from '../OEditorial';
import { OFactGrid } from '../OFactGrid';
import { OFootnotes } from '../OFootnotes';
import { OForm } from '../OForm';
import { OHeyFlowForm } from '../OHeyFlowForm';
import { OInfoteaser } from '../OInfoteaser';
import { OList } from '../OList';
import { OLogos } from '../OLogos';
import { OMedia } from '../OMedia';
import { OSnowflake } from '../OSnowflake';
import { OStageArticle } from '../OStageArticle/OStageArticle';
import { OStageFull } from '../OStageFull/OStageFull';
import { OStageNarrow } from '../OStageNarrow/OStageNarrow';
import { OStageSplit } from '../OStageSplit/OStageSplit';
import { OStandoutText } from '../OStandoutText';
import { OTable } from '../OTable';
import { OTariffComparison } from '../OTariffComparison';
import { OTeaserGrid, OTeaserGridProps } from '../OTeaserGrid';
import { OTeaserGridArticles } from '../OTeaserGridArticles';
import { OTeaserList } from '../OTeaserList';
import { OText } from '../OText';
import { OToast } from '../OToast';
import { OWallboxConfigurator } from '../OWallboxConfigurator';

const StageContent: FC<{ contentList: TProductType['stage'] }> = ({ contentList }) => {
  if (!contentList?.[0]) {
    return null;
  }

  const content = contentList[0];

  switch (content.component) {
    case 'o-stage-full':
      return <OStageFull {...content} />;
    case 'o-stage-narrow':
      return <OStageNarrow {...content} />;
    case 'o-stage-split':
      return <OStageSplit {...content} />;
    case 'o-stage-article':
      return <OStageArticle {...content} />;
    default:
      assertExhaustive(content);
  }
};

const PartialContent: FC<{ content: Exclude<TProductType['partials'], undefined>[number]; index: number }> = ({
  content,
  index,
}) => {
  if (!content) {
    return null;
  }

  switch (content.component) {
    case 'o-accordion':
      return <OAccordion {...content} />;
    case 'o-car-calculator':
      return <OCarCalculator {...content} />;
    case 'o-carousel':
      return <OCarousel {...content} />;
    case 'o-columns':
      return <OColumns {...content} />;
    case 'o-editorial':
      return <OEditorial {...content} />;
    case 'o-fact-grid':
      return <OFactGrid {...content} />;
    case 'o-form':
      return <OForm {...content} />;
    case 'o-list':
      return <OList {...content} />;
    case 'o-logos':
      return <OLogos {...content} />;
    case 'o-media':
      return <OMedia {...content} index={index} />;
    case 'o-snowflake':
      return <OSnowflake {...content} />;
    case 'o-standout-text':
      return <OStandoutText {...content} />;
    case 'o-tariff-comparison':
      return <OTariffComparison {...content} />;
    case 'o-teaser-grid':
      return <OTeaserGrid {...(content as OTeaserGridProps)} />; // type will become obsolete with rsc refactor
    case 'o-teaser-list':
      return <OTeaserList {...content} />;
    case 'o-text':
      return <OText {...content} />;
    case 'o-wallbox-configurator':
      return <OWallboxConfigurator {...content} />;
    case 'o-table':
      return <OTable {...content} />;
    case 'o-infoteaser':
      return <OInfoteaser {...content} />;
    case 'o-teaser-grid-articles':
      return <OTeaserGridArticles {...content} />;
    case 'o-all-in-one-calculator':
      return <OAllInOneCalculator {...content} />;
    case 'o-download':
      return <ODownload {...content} />;
    case 'o-toast':
      return <OToast {...content} />;
    case 'solar-stage':
      return <SolarStage {...content} />;
    case 'four-steps':
      return <FourSteps {...content} />;
    case 'solar-product-bundle':
      return <SolarProductBundle {...content} />;
    case 'case-study':
      return <CaseStudy {...content} />;
    case 'installion-promo':
      return <Promo {...content} />;
    case 'solar-slider':
      return <SolarSlider {...content} />;
    case 'solar-checkout-teaser':
      return <SolarCheckoutTeaser {...content} />;
    case 'lead-form':
      return <LeadForm {...content} />;
    case 'cases-slider':
      return <CasesSlider {...content} />;
    case 'o-eauto-tariff-comparison':
      return <OEAutoTariffComparison {...content} />;
    case 'o-heyflow-form':
      return <OHeyFlowForm />;
    case 'soft-funnel-entry':
      return <SoftFunnelEntry {...content} />;
    default:
      assertExhaustive(content);
  }
};

export const TProduct: FC<WithMetaData<StoryblokStoryType<TProductType>>> = ({
  content: {
    breadcrumbTitle,
    footnotes,
    metaTitle,
    partials,
    secondaryNavigation,
    secondaryNavigationButtonIntroOnDesktop: isButtonIntroOnDesktop,
    stage,
    trackingContentGroup,
    trackingContentGroup2,
    trackingContentGroup3,
    trackingPageArea,
  },
  parentPages,
  slug,
}) => {
  useTemplateTracker({
    contentGroup: trackingContentGroup,
    contentGroup2: trackingContentGroup2,
    contentGroup3: trackingContentGroup3,
    pageArea: trackingPageArea ?? '',
    pageType: 'product',
    pageTitle: metaTitle ?? '',
  });

  return (
    <Layout breadcrumbTitle={breadcrumbTitle} parentPages={parentPages} slug={slug}>
      {secondaryNavigation?.length === 1 && (
        <MNavigationSecondary {...secondaryNavigation[0]} isButtonIntroOnDesktop={isButtonIntroOnDesktop} />
      )}
      <StageContent contentList={stage} />

      {partials?.map((item, index) => item && <PartialContent content={item} index={index} key={item._uid} />)}
      {Boolean(footnotes?.length) && <OFootnotes footnotes={footnotes} />}
    </Layout>
  );
};

'use client';

import { FC, ReactNode } from 'react';

import { TeaserItem } from '@lichtblick/photon/molecules/teaser-item/teaser-item';

import { getHref } from '../../helpers/links';
import { MTeaserItemType } from '../../types/storyblok';
import { ColorKey, photonColorMap } from '../../utils';
import { AImage } from '../AImage/AImage';
import { AVideo } from '../AVideo/AVideo';

export type MTeaserItemProps = Omit<MTeaserItemType, 'text'> & {
  accentColor?: ColorKey;
  className?: string;
  isTextOutside?: boolean;
  text?: MTeaserItemType['text'] | ReactNode;
};

export const MTeaserItem: FC<MTeaserItemProps> = ({
  accentColor,
  button,
  chip,
  className,
  headline,
  isTextOutside = false,
  media,
  text,
  trackingIdentifier,
}) => {
  const buttonData = button[0];
  const href = getHref(buttonData);
  const tracking = trackingIdentifier ? { eventName: trackingIdentifier } : undefined;

  const image = media?.[0]?.component === 'a-image' ? <AImage {...media[0]} /> : undefined;
  const video = media?.[0]?.component === 'a-video' ? <AVideo {...media[0]} /> : undefined;

  return (
    <TeaserItem
      backgroundColor={accentColor ? photonColorMap[accentColor] : undefined}
      chip={chip}
      className={className}
      headline={headline}
      link={{
        title: buttonData.text ?? '',
        href,
        isDownloadLink: buttonData.isDownloadLink ?? false,
        shouldOpenInNewTab: buttonData.openInNewTab ?? false,
      }}
      media={image || video}
      text={text}
      textOutside={isTextOutside}
      tracking={tracking}
    />
  );
};

import { FC, useContext } from 'react';

import { assertExhaustive } from '../../helpers/assertExhaustive';
import { SettingsContext } from '../../helpers/settingsContext';
import { WithMetaData } from '../../helpers/templateMapper';
import { useTemplateTracker } from '../../hooks';
import { StoryblokStoryType, TArticleType } from '../../types/storyblok';
import { SoftFunnelEntry } from '../eaas/SoftFunnelEntry/SoftFunnelEntry';
import { SolarProductBundle } from '../eaas/SolarProductBundle';
import { Layout } from '../Layout';
import { MBreadcrumb } from '../MBreadcrumb/MBreadcrumb';
import { OAccordion } from '../OAccordion/OAccordion';
import { OAllInOneCalculator } from '../OAllInOneCalculator/OAllInOneCalculator';
import { OCarCalculator } from '../OCarCalculator/OCarCalculator';
import { OCarousel } from '../OCarousel/OCarousel';
import { OColumns } from '../OColumns/OColumns';
import { ODownload } from '../ODownload/ODownload';
import { OEditorial } from '../OEditorial/OEditorial';
import { OFactGrid } from '../OFactGrid/OFactGrid';
import { OFootnotes } from '../OFootnotes/OFootnotes';
import { OForm } from '../OForm/OForm';
import { OIndex } from '../OIndex/OIndex';
import { OInfoteaser } from '../OInfoteaser/OInfoteaser';
import { OList } from '../OList/OList';
import { OLogos } from '../OLogos/OLogos';
import { OMedia } from '../OMedia/OMedia';
import { OProductOverview } from '../OProductOverview/OProductOverview';
import { OSnowflake } from '../OSnowflake/OSnowflake';
import { OStageArticle } from '../OStageArticle';
import { OStandoutText } from '../OStandoutText/OStandoutText';
import { OTable } from '../OTable/OTable';
import { OTariffComparison } from '../OTariffComparison';
import { OTeaserGrid, OTeaserGridProps } from '../OTeaserGrid/OTeaserGrid';
import { OTeaserGridArticles, TeaserGridArticlesProps } from '../OTeaserGridArticles';
import { OTeaserList } from '../OTeaserList/OTeaserList';
import { OText } from '../OText/OText';
import OWallboxConfigurator from '../OWallboxConfigurator/OWallboxConfigurator';

export type TArticleProps = TArticleType & {
  customRelatedArticlesGrid?: TeaserGridArticlesProps;
  relatedArticlesGrid?: TeaserGridArticlesProps;
};

const PartialContent: FC<{ content: Exclude<TArticleType['partials'], undefined>[number]; index: number }> = ({
  content,
  index,
}) => {
  if (!content) {
    return null;
  }

  switch (content.component) {
    case 'o-accordion':
      return <OAccordion {...content} />;
    case 'o-carousel':
      return <OCarousel {...content} />;
    case 'o-columns':
      return <OColumns {...content} />;
    case 'o-editorial':
      return <OEditorial {...content} />;
    case 'o-fact-grid':
      return <OFactGrid {...content} />;
    case 'o-form':
      return <OForm {...content} />;
    case 'o-list':
      return <OList {...content} />;
    case 'o-logos':
      return <OLogos {...content} />;
    case 'o-media':
      return <OMedia {...content} index={index} />;
    case 'o-snowflake':
      return <OSnowflake {...content} />;
    case 'o-standout-text':
      return <OStandoutText {...content} />;
    case 'o-tariff-comparison':
      return <OTariffComparison {...content} />;
    case 'o-teaser-grid':
      return <OTeaserGrid {...(content as OTeaserGridProps)} />; // type will become obsolete with rsc refactor
    case 'o-teaser-list':
      return <OTeaserList {...content} />;
    case 'o-text':
      return <OText {...content} />;
    case 'o-wallbox-configurator':
      return <OWallboxConfigurator {...content} />;
    case 'o-table':
      return <OTable {...content} />;
    case 'o-infoteaser':
      return <OInfoteaser {...content} />;
    case 'o-all-in-one-calculator':
      return <OAllInOneCalculator {...content} />;
    case 'o-download':
      return <ODownload {...content} />;
    case 'o-index':
      return <OIndex {...content} />;
    case 'o-car-calculator':
      return <OCarCalculator {...content} />;
    case 'o-product-overview':
      return <OProductOverview {...content} />;
    case 'soft-funnel-entry':
      return <SoftFunnelEntry {...content} />;
    case 'solar-product-bundle':
      return <SolarProductBundle {...content} />;
    default:
      assertExhaustive(content);
  }
};

export const TArticle: FC<WithMetaData<StoryblokStoryType<TArticleProps>>> = ({
  content: {
    _uid,
    breadcrumbTitle,
    customRelatedArticlesGrid,
    footnotes,
    metaTitle,
    partials,
    relatedArticlesGrid,
    stage,
    trackingContentGroup,
    trackingContentGroup2,
    trackingContentGroup3,
    trackingPageArea,
  },
  parentPages,
  slug,
  uuid,
}) => {
  const { relatedArticlesTextIntro } = useContext(SettingsContext);

  useTemplateTracker({
    contentGroup: trackingContentGroup,
    contentGroup2: trackingContentGroup2,
    contentGroup3: trackingContentGroup3,
    pageArea: trackingPageArea ?? '',
    pageType: 'article',
    pageTitle: metaTitle ?? '',
  });

  return (
    <Layout breadcrumbTitle={breadcrumbTitle} parentPages={parentPages} slug={slug}>
      {breadcrumbTitle && slug && Boolean(parentPages.length) && (
        <MBreadcrumb breadcrumbTitle={breadcrumbTitle} mode="content" parentPages={parentPages} slug={slug} />
      )}
      {stage?.length === 1 && <OStageArticle {...stage[0]} />}
      {partials?.map((item, index) => item && <PartialContent content={item} index={index} key={item._uid} />)}
      {customRelatedArticlesGrid?.articles?.length ? (
        <OTeaserGridArticles
          _uid={_uid}
          accent={customRelatedArticlesGrid?.accent ?? 'white'}
          articles={customRelatedArticlesGrid?.articles}
          component="o-teaser-grid-articles"
          currentPageId={_uid}
          limit={customRelatedArticlesGrid?.limit}
          tags={customRelatedArticlesGrid?.tags}
          textIntro={customRelatedArticlesGrid?.textIntro ?? relatedArticlesTextIntro}
          theme={customRelatedArticlesGrid?.theme ?? 'white'}
        />
      ) : relatedArticlesGrid?.articles?.length ? (
        <OTeaserGridArticles
          _uid={uuid}
          accent={'faded-gray'}
          articles={relatedArticlesGrid?.articles}
          component="o-teaser-grid-articles"
          currentPageId={_uid}
          limit="3"
          textIntro={relatedArticlesTextIntro}
          theme={'white'}
        />
      ) : null}
      {<OFootnotes footnotes={footnotes} />}
    </Layout>
  );
};

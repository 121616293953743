'use client';

import NextLink from 'next/link';
import { AnchorHTMLAttributes, FC, PropsWithChildren, useContext } from 'react';

import { isExternalRoute } from './helpers/is-external-route';
import { LinkContext } from './helpers/link-context';

type LinkProps = PropsWithChildren &
  Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> & { className?: string; href: string };

const Link: FC<LinkProps> = ({ children, href, ...props }) => {
  const externalPaths = useContext(LinkContext);

  if (!externalPaths || !isExternalRoute(href, externalPaths)) {
    return (
      <NextLink {...props} href={href} prefetch={false}>
        {children}
      </NextLink>
    );
  }

  return (
    <a {...props} href={href}>
      {children}
    </a>
  );
};

export default Link;

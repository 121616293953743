import Head from 'next/head';
import { FC } from 'react';

import 'iframe-resizer/js/iframeResizer.contentWindow';
import { assertExhaustive } from '../../helpers/assertExhaustive';
import { WithMetaData } from '../../helpers/templateMapper';
import { StoryblokStoryType, TFrameContentType } from '../../types/storyblok';
import { Layout } from '../Layout';
import { OAccordion } from '../OAccordion/OAccordion';
import { OAllInOneCalculator } from '../OAllInOneCalculator/OAllInOneCalculator';
import { OCarousel } from '../OCarousel/OCarousel';
import { OColumns } from '../OColumns/OColumns';
import { ODownload } from '../ODownload/ODownload';
import { OEditorial } from '../OEditorial/OEditorial';
import { OFactGrid } from '../OFactGrid/OFactGrid';
import { OFootnotes } from '../OFootnotes';
import { OForm } from '../OForm/OForm';
import { OInfoteaser } from '../OInfoteaser/OInfoteaser';
import { OList } from '../OList/OList';
import { OLogos } from '../OLogos/OLogos';
import { OMedia } from '../OMedia/OMedia';
import { OStageArticle } from '../OStageArticle/OStageArticle';
import { OStageFull } from '../OStageFull/OStageFull';
import { OStageNarrow } from '../OStageNarrow/OStageNarrow';
import { OStageSplit } from '../OStageSplit/OStageSplit';
import { OStandoutText } from '../OStandoutText/OStandoutText';
import OTariffComparison from '../OTariffComparison/OTariffComparison';
import { OTeaserGrid, OTeaserGridProps } from '../OTeaserGrid/OTeaserGrid';
import { OTeaserList } from '../OTeaserList/OTeaserList';
import { OText } from '../OText/OText';
import OWallboxConfigurator from '../OWallboxConfigurator/OWallboxConfigurator';

const StageContent: FC<{ contentList: TFrameContentType['stage'] }> = ({ contentList }) => {
  if (!contentList?.[0]) {
    return null;
  }

  const content = contentList[0];

  switch (content.component) {
    case 'o-stage-full':
      return <OStageFull {...content} />;
    case 'o-stage-narrow':
      return <OStageNarrow {...content} />;
    case 'o-stage-split':
      return <OStageSplit {...content} />;
    case 'o-stage-article':
      return <OStageArticle {...content} />;
    default:
      assertExhaustive(content);
  }
};

const PartialContent: FC<{ content: Exclude<TFrameContentType['partials'], undefined>[number]; index: number }> = ({
  content,
  index,
}) => {
  if (!content) {
    return null;
  }

  switch (content.component) {
    case 'o-accordion':
      return <OAccordion {...content} />;
    case 'o-carousel':
      return <OCarousel {...content} />;
    case 'o-columns':
      return <OColumns {...content} />;
    case 'o-editorial':
      return <OEditorial {...content} />;
    case 'o-fact-grid':
      return <OFactGrid {...content} />;
    case 'o-form':
      return <OForm {...content} />;
    case 'o-list':
      return <OList {...content} />;
    case 'o-logos':
      return <OLogos {...content} />;
    case 'o-media':
      return <OMedia {...content} index={index} />;
    case 'o-standout-text':
      return <OStandoutText {...content} />;
    case 'o-tariff-comparison':
      return <OTariffComparison {...content} />;
    case 'o-teaser-grid':
      return <OTeaserGrid {...(content as OTeaserGridProps)} />; // type will become obsolete with rsc refactor
    case 'o-teaser-list':
      return <OTeaserList {...content} />;
    case 'o-text':
      return <OText {...content} />;
    case 'o-wallbox-configurator':
      return <OWallboxConfigurator {...content} />;
    case 'o-infoteaser':
      return <OInfoteaser {...content} />;
    case 'o-all-in-one-calculator':
      return <OAllInOneCalculator {...content} />;
    case 'o-download':
      return <ODownload {...content} />;
    default:
      assertExhaustive(content);
  }
};

export const TFrameContent: FC<WithMetaData<StoryblokStoryType<TFrameContentType>>> = ({
  content: {
    breadcrumbTitle,
    footerCustomContactPhone,
    footerCustomContactSmallText,
    footerCustomContactText,
    footerCustomSecondaryNavigation,
    footerLayout,
    footnotes,
    partials,
    stage,
  },
  parentPages,
  slug,
}) => (
  <>
    <Head>
      <base target="_blank" />
    </Head>

    <Layout
      breadcrumbTitle={breadcrumbTitle}
      footerCustomContactPhone={footerCustomContactPhone}
      footerCustomContactSmallText={footerCustomContactSmallText}
      footerCustomContactText={footerCustomContactText}
      footerLayout={footerLayout as any}
      footerSecondaryNavigation={footerCustomSecondaryNavigation}
      hasNoHeader={true}
      parentPages={parentPages}
      slug={slug}
    >
      <StageContent contentList={stage} />
      {partials?.map((item, index) => item && <PartialContent content={item} index={index} key={item._uid} />)}
      <OFootnotes footnotes={footnotes} />
    </Layout>
  </>
);

import { FC } from 'react';
import { ISbStoriesParams } from 'storyblok-js-client';

import { TeaserGridArticlesProps } from './OTeaserGridArticles';
import { buildArticleTeaser } from './OTeaserGridArticles/OTeaserGridArticles.helpers';
import { TeaserListProps } from './OTeaserList/OTeaserList';
import { addMeta } from './OTeaserList/OTeaserList.helpers';
import { TArticle, TArticleProps } from './TArticle';
import { TDetail, TDetailProps } from './TDetail';
import { TFormProcess } from './TFormProcess';
import { TFrameContent } from './TFrameContent';
import { THome } from './THome';
import { TJobDetail } from './TJobDetail';
import { TNotification } from './TNotification';
import { TOverview } from './TOverview';
import { TPressDetail, TPressDetailProps } from './TPressDetail';
import { TProduct } from './TProduct';
import { TReferral } from './TReferral';
import { TServiceView } from './TServiceView';
import { TStory } from './TStory';

import { storyblokClient } from '../client';
import { type WithMetaData } from '../helpers/templateMapper';
import {
  OTeaserListType,
  StoryblokStoryType,
  TArticleType,
  TDetailType,
  TemplatesType,
  TPressDetailType,
} from '../types';

export type TemplateIds = TemplatesType['component'];
export type ExtendedTemplates =
  | Exclude<TemplatesType, TArticleType | TDetailType | TPressDetailType>
  | TArticleProps
  | TDetailProps
  | TPressDetailProps;

const getRelatedArticleTeasers = async (tags: string[] | undefined, storyblokClientParams: ISbStoriesParams) => {
  const response = await storyblokClient.getStories({
    ...storyblokClientParams,
    per_page: 100,
    content_type: 't-article',
    filter_query: tags ? { tags: { in: tags.join(',') } } : {},
    sort_by: 'published_at:desc',
  });

  return (response.data.stories as StoryblokStoryType<TArticleType>[]).map(buildArticleTeaser);
};

const getTPressDetailItems = async (
  { excluded, filterByYear, limit }: Pick<OTeaserListType, 'excluded' | 'filterByYear' | 'limit'>,
  storyblokClientParams: ISbStoriesParams,
) => {
  const filterQuery = filterByYear ? { articleDate: { like: `*${filterByYear}*` } } : {};

  const response = await storyblokClient.getStories({
    ...storyblokClientParams,
    per_page: 100,
    content_type: 't-press-detail',
    filter_query: filterQuery,
  });

  const pressDetailItems = response.data.stories as StoryblokStoryType<TPressDetailType>[];

  return pressDetailItems
    .filter((item) => !excluded?.includes(item.uuid))
    .sort((a, b) => new Date(b.content.articleDate).getTime() - new Date(a.content.articleDate).getTime())
    .slice(0, limit ? Number(limit) : undefined);
};

type TemplatesWithPartials = NonNullable<Extract<TemplatesType, { partials?: unknown[] }>>;

export const extendComponent = async <T extends TemplatesWithPartials['component']>(
  partial: NonNullable<Extract<TemplatesType, { component: T }>['partials']>[number],
  storyblokClientParams: ISbStoriesParams,
): Promise<void> => {
  switch (partial.component) {
    case 'o-teaser-grid-articles':
      (partial as TeaserGridArticlesProps).articles = await getRelatedArticleTeasers(
        partial.tags,
        storyblokClientParams,
      );

      break;

    case 'o-teaser-list': {
      const jobOrPressDetailItems =
        (partial.included?.length ? partial.included : undefined) ??
        (partial.includedTeasers?.length ? partial.includedTeasers : undefined) ??
        (await getTPressDetailItems(partial, storyblokClientParams));

      (partial as TeaserListProps).listItems = jobOrPressDetailItems.map(addMeta).filter((item) => item !== undefined);

      break;
    }
  }
};

/**
 * Extends the page templates with additional related data from other pages.
 *
 * In order to avoid type conflicts (because story has to extend our extended template type to avoid a type assertion)
 * this function adds new properties inside the templates instead of mutating existing ones.
 *
 * @template T
 * @param {T & StoryblokStoryType<ExtendedTemplates>} story - The story object containing the template- and metadata.
 * @returns {Promise<T & StoryblokStoryType<ExtendedTemplates>>} A promise that resolves to the story containing the extended template.
 *
 */
export const extendTemplates = async <T extends StoryblokStoryType<ExtendedTemplates>>(
  story: T,
  storyblokClientParams: ISbStoriesParams,
): Promise<T> => {
  const { content } = story;

  switch (content.component) {
    case 't-article': {
      const customRelatedArticles = content?.customRelatedArticles?.[0];

      if (customRelatedArticles) {
        const articles = customRelatedArticles?.items?.length
          ? customRelatedArticles.items.map(buildArticleTeaser)
          : await getRelatedArticleTeasers(customRelatedArticles.tags, storyblokClientParams);

        content.customRelatedArticlesGrid = {
          articles,
          ...structuredClone(customRelatedArticles),
        };
      } else {
        content.relatedArticlesGrid = {
          _uid: 'related-articles',
          component: 'o-teaser-grid-articles',
          accent: 'faded-gray',
          currentPageId: story.uuid,
          theme: 'white',
          articles: (await getRelatedArticleTeasers(content.tags, storyblokClientParams)).filter(
            (teaser) => teaser._uid !== story.uuid,
          ),
        };
      }

      const { partials } = content;

      if (partials && partials.length > 0) {
        await Promise.all(partials.map((partial) => extendComponent(partial, storyblokClientParams)));
      }

      break;
    }

    case 't-press-detail': {
      const items = await getTPressDetailItems(
        {
          excluded: [story.uuid],
        },
        storyblokClientParams,
      );

      content.listItems = items
        .map(addMeta)
        .filter((item) => item !== undefined)
        .filter((item) => item.meta?.[1] === content.category)
        .slice(0, 3);

      break;
    }

    case 't-product':
    case 't-overview':
    case 't-home':
    case 't-frame-content':
    case 't-detail': {
      const { partials } = content;

      if (partials && partials.length > 0) {
        await Promise.all(partials.map((partial) => extendComponent(partial, storyblokClientParams)));
      }

      break;
    }
  }

  return story;
};

export type TemplateMap = {
  [K in TemplateIds]: FC<WithMetaData<StoryblokStoryType<Extract<ExtendedTemplates, { component: K }>>>>;
};

export const TEMPLATE_MAP: TemplateMap = {
  't-article': TArticle,
  't-detail': TDetail,
  't-form-process': TFormProcess,
  't-frame-content': TFrameContent,
  't-home': THome,
  't-job-detail': TJobDetail,
  't-notification': TNotification,
  't-overview': TOverview,
  't-press-detail': TPressDetail,
  't-product': TProduct,
  't-referral': TReferral,
  't-service-view': TServiceView,
  't-story': TStory,
} as const;

export const STORY_COMPONENTS = Object.keys(TEMPLATE_MAP);

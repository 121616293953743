import { ChangeEvent, FC, useState } from 'react';

import { formatPrice } from '@lichtblick/localization';
import { AccentText, ColorSelector, Headline, Text } from '@lichtblick/ui-components';

import {
  ButtonWrapper,
  ColorSelectorWrapper,
  Curtain,
  Dropdown,
  HeadlineWrapper,
  ImageContent,
  ImageWrapper,
  ListHeadlineWrapper,
  ListSubHeadlineWrapper,
  Price,
  ProductComparisonCard,
  StyledHeadline,
  StyledImage,
  StyledList,
} from './MProductComparisonCard.styles';

import { MProductComparisonCardType } from '../../types/storyblok';
import { AButton } from '../AButton';
import { AImage } from '../AImage/AImage';
import { MTechnicalDetails } from '../MTechnicalDetails';
import { ProductWithInformation } from '../OProductComparison/OProductComparison.helpers';

export type ProductComparisonCardProps = MProductComparisonCardType &
  ProductWithInformation & {
    availableProducts?: ProductWithInformation[];
    onChange?: ({ currentOrder, newValue }: { currentOrder: number; newValue: string }) => void;
  };

export const MProductComparisonCard: FC<ProductComparisonCardProps> = ({
  _uid,
  availableProducts = [],
  button,
  campaign,
  colors,
  headline,
  list,
  listHeadline,
  listSubline,
  media,
  onChange = () => null,
  order,
  price,
  productCodes,
  technicalDetails,
  theme,
  value,
}) => {
  const [selectedColor, setSelectedColor] = useState<string>(colors?.[0]?.color);
  const handleColorChange = (color: string) => setSelectedColor(color);

  const handleOnChange = (e: ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();

    onChange({ newValue: e.target.value, currentOrder: order });
  };

  return (
    <ProductComparisonCard data-campaign={campaign} data-product-codes={productCodes?.value?.join(' ')} theme={theme}>
      <Curtain>
        <Dropdown $theme={theme}>
          <select onChange={handleOnChange} value={value}>
            {availableProducts.map((product) => (
              <option key={product.value} label={product.label}>
                {product.value}
              </option>
            ))}
          </select>
        </Dropdown>
        <HeadlineWrapper>
          <StyledHeadline renderAs={'h3'} size="S">
            {headline}
          </StyledHeadline>
        </HeadlineWrapper>
      </Curtain>
      <ImageWrapper>
        <ImageContent>
          {media?.length === 1 ? (
            <AImage {...media[0]} />
          ) : (
            colors.map(
              (color) =>
                color.image && (
                  <StyledImage
                    description={color.textContent}
                    isSelected={selectedColor === color.color}
                    key={color.color}
                    url={{ mobile: color.image }}
                  />
                ),
            )
          )}
        </ImageContent>
      </ImageWrapper>
      <ColorSelectorWrapper>
        <ColorSelector
          name={`wallboxColor-${_uid}`}
          onChange={handleColorChange}
          options={colors}
          value={selectedColor}
        />
      </ColorSelectorWrapper>
      <Price $hasPrice={!!price}>
        <AccentText size="M">{`ab ${formatPrice(price, '€', 2)}`}</AccentText>
      </Price>
      <ButtonWrapper>{button?.length === 1 && <AButton {...button[0]} />}</ButtonWrapper>
      {technicalDetails?.map((item) => item && <MTechnicalDetails key={item._uid} {...item} />)}
      {(list?.[0]?.items?.length ?? 0) > 0 && (
        <>
          {listHeadline && (
            <ListHeadlineWrapper>
              <Headline renderAs={'h3'} size="S">
                {listHeadline}
              </Headline>
            </ListHeadlineWrapper>
          )}
          {listSubline && (
            <ListSubHeadlineWrapper>
              <Text renderAs={'span'} size="M">
                {listSubline}
              </Text>
            </ListSubHeadlineWrapper>
          )}
          <StyledList
            items={
              list?.[0].items?.filter((item) => !!item?.richText).map((item) => ({ richText: item?.richText ?? {} })) ??
              []
            }
          />
        </>
      )}
    </ProductComparisonCard>
  );
};

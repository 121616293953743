import classnames from 'classnames';
import { ComponentProps, forwardRef, PropsWithChildren } from 'react';

import styles from './chip.module.scss';

import { Colors } from '../../types/colors';
import { Text } from '../text/text';

export type ChipProps = PropsWithChildren &
  Omit<ComponentProps<'span'>, 'ref'> & {
    backgroundColor?: Colors;
    textColor?: Colors;
  };

export const Chip = forwardRef<HTMLSpanElement, ChipProps>(
  ({ backgroundColor = 'Black', children, textColor = 'White', ...props }, ref) => (
    <span
      ref={ref}
      {...props}
      className={classnames(
        'chip',
        styles.chip,
        styles[`bg-${backgroundColor}`],
        Boolean(props.onClick) && styles.clickable,
        props.className,
      )}
    >
      <Text className={styles[`color-${textColor}`]} size="xs">
        {children}
      </Text>
    </span>
  ),
);

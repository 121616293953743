import { FC, PropsWithChildren } from 'react';

import { Spacing } from '@lichtblick/theme';
import { Box } from '@lichtblick/ui-components';

import { MediaFigure } from './MediaFigure';
import styles from './OMedia.module.scss';

import { MTextIntroType, OMediaType } from '../../types/storyblok';
import { getColor, getSpacing } from '../../utils';
import { MCalculatorBox } from '../MCalculatorBox';
import { MTextIntro } from '../MTextIntro';
import { MTextIntroPrice } from '../MTextIntroPrice/MTextIntroPrice';
import { AnchorMark, GridColumn, GridContainer, GridRow } from '../shared';

const isMTextIntro = (
  textIntro: Exclude<OMediaType['textIntro'], undefined>[number] | undefined,
): textIntro is MTextIntroType => textIntro?.component === 'm-text-intro';

type OMediaProps = OMediaType & {
  index: number;
  isCenteredDesktop?: boolean;
  isCenteredMobile?: boolean;
};

type GridProps = PropsWithChildren & {
  layout: OMediaProps['layout'];
};

const Grid: FC<GridProps> = ({ children, layout }) => {
  if (layout === 'large') {
    return children;
  } else {
    return (
      <GridContainer>
        <GridRow>
          <GridColumn $push={layout === 'small' ? 2 : undefined} $width={layout === 'medium' ? 12 : 8}>
            {children}
          </GridColumn>
        </GridRow>
      </GridContainer>
    );
  }
};

export const OMedia: FC<OMediaProps> = ({
  anchorId,
  caption,
  copyright,
  embed,
  index,
  isCenteredDesktop = true,
  isCenteredMobile = true,
  layout,
  media,
  paddingBottomDesktop,
  paddingBottomMobile,
  paddingTopDesktop,
  paddingTopMobile,
  textIntro,
  theme,
}) => {
  const isNarrow = isMTextIntro(textIntro?.[0]) && isCenteredDesktop;

  return (
    <Box
      backgroundColor={getColor(theme)}
      data-testid="media"
      pbd={getSpacing(paddingBottomDesktop) ?? Spacing.Xl}
      pbm={getSpacing(paddingBottomMobile) ?? Spacing.L}
      ptd={getSpacing(paddingTopDesktop) ?? Spacing.Xl}
      ptm={getSpacing(paddingTopMobile) ?? Spacing.L}
    >
      {anchorId && <AnchorMark anchorId={anchorId} />}
      {textIntro?.length === 1 && (
        <GridContainer>
          <GridRow>
            <GridColumn $push={isNarrow ? 2 : undefined} $width={isNarrow ? 8 : 12}>
              <div className={styles.wrapper}>
                {isMTextIntro(textIntro?.[0]) ? (
                  <MTextIntro
                    {...textIntro[0]}
                    isCenteredDesktop={isCenteredDesktop}
                    isCenteredMobile={isCenteredMobile}
                  />
                ) : (
                  <MTextIntroPrice {...textIntro[0]} />
                )}
              </div>
            </GridColumn>
          </GridRow>
        </GridContainer>
      )}

      <Grid layout={layout}>
        <MediaFigure caption={caption} copyright={copyright} index={index} layout={layout} media={media} />
        {embed?.[0] && <MCalculatorBox {...embed[0]} className={styles['calculator-box']} />}
      </Grid>
    </Box>
  );
};

import { FC } from 'react';

import { Spacing } from '@lichtblick/theme';
import { AccentText, Box } from '@lichtblick/ui-components';

import {
  GridColumnsExtend,
  GridContainerExtend,
  Price,
  StyledHeadline,
  StyledPriceSmallText,
  StyledTagline,
  StyledText,
  WrapperList,
} from './OEditorial.styles';

import { assertExhaustive } from '../../helpers/assertExhaustive';
import { OEditorialType } from '../../types/storyblok';
import { getButtonVariant, getColor, getSpacing } from '../../utils';
import { AButton } from '../AButton';
import { AImage } from '../AImage/AImage';
import { AVideo } from '../AVideo/AVideo';
import { MAnimatedIllustration } from '../MAnimatedIllustration/MAnimatedIllustration';
import { MList } from '../MList';
import { AnchorMark, GridColumn, GridRow, getContainerStyle } from '../shared';

const MediaContent: FC<{ content: OEditorialType['media'] }> = ({ content: contentList }) => {
  if (!contentList?.[0]) {
    return null;
  }

  const content = contentList[0];

  switch (content.component) {
    case 'a-image':
      return <AImage {...content} />;
    case 'a-video':
      return <AVideo {...content} />;
    case 'm-animated-illustration':
      return <MAnimatedIllustration {...content} />;
    default:
      assertExhaustive(content);
  }
};

export const OEditorial: FC<OEditorialType> = ({
  accent,
  alignedLeft: isLeftAligned = true,
  anchorId,
  button,
  buttonType,
  containerStyle,
  headline,
  list,
  media,
  paddingBottomDesktop,
  paddingBottomMobile,
  paddingTopDesktop,
  paddingTopMobile,
  priceSmallText,
  priceValue,
  tagline,
  text,
  theme,
}) => (
  <Box
    backgroundColor={getColor(theme)}
    data-testid="editorial"
    pbd={getSpacing(paddingBottomDesktop) ?? Spacing.M}
    pbm={getSpacing(paddingBottomMobile) ?? Spacing.Xs}
    ptd={getSpacing(paddingTopDesktop) ?? Spacing.M}
    ptm={getSpacing(paddingTopMobile) ?? Spacing.Xs}
  >
    {anchorId && <AnchorMark anchorId={anchorId} />}
    <GridContainerExtend $backgroundColor={getColor(accent)} $containerStyle={getContainerStyle(containerStyle)}>
      <GridRow $isReversed={isLeftAligned}>
        {media?.[0] && (
          <GridColumn $isAlignedMiddle={true} $width={6}>
            <Box px={Spacing.Xxs}>
              <MediaContent content={media} />
            </Box>
          </GridColumn>
        )}
        <GridColumnsExtend $isAlignedMiddle={true} $isLeftAligned={isLeftAligned} $width={6}>
          {tagline && <StyledTagline isBold>{tagline}</StyledTagline>}
          {headline && (
            <StyledHeadline renderAs="h2" size="M">
              {headline}
            </StyledHeadline>
          )}
          {text && <StyledText>{text}</StyledText>}
          {list?.[0] && (
            <WrapperList>
              <MList {...list[0]} colorOverride={accent} />
            </WrapperList>
          )}
          {(priceValue || priceSmallText) && (
            <Price>
              {priceValue && <AccentText>{priceValue}</AccentText>}
              {priceSmallText && <StyledPriceSmallText size="Xs">{priceSmallText}</StyledPriceSmallText>}
            </Price>
          )}
          {button?.[0] && <AButton {...button[0]} variant={getButtonVariant(buttonType) ?? 'link'} />}
        </GridColumnsExtend>
      </GridRow>
    </GridContainerExtend>
  </Box>
);

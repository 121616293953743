import { FC } from 'react';

export const OWallboxConfiguratorIcons: FC = () => (
  <svg
    aria-hidden="true"
    style={{ position: 'absolute', width: 0, height: 0, overflow: 'hidden' }}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <symbol id="check-xs" viewBox="0 0 16 16">
        <path
          clipRule="evenodd"
          d="M5.0996 8.29524C5.51011 7.92578 6.1424 7.95906 6.51186 8.36957L7.12528 9.05115L9.74891 5.62631C10.0848 5.18788 10.7125 5.10473 11.1509 5.44059C11.5893 5.77645 11.6724 6.40413 11.3366 6.84256L7.98101 11.2229C7.80021 11.4589 7.52378 11.6022 7.22671 11.614C6.92965 11.6257 6.64275 11.5047 6.44387 11.2837L5.02527 9.7075C4.65581 9.29699 4.68909 8.6647 5.0996 8.29524ZM8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
          fillRule="evenodd"
        />
      </symbol>
      <symbol id="electricity-m" viewBox="0 0 32 32">
        <path
          clipRule="evenodd"
          d="M11.69 2.46328C11.9309 1.59835 12.7188 1 13.6166 1h7.0227c1.3496 0 2.3117 1.30925 1.9087 2.59725l-2.1397 6.83815h3.5604c1.6595 0 2.5971 1.9045 1.585 3.2197L12.5068 30.6099c-.2873.3733-.7962.4955-1.2217.2933s-.6522-.6739-.5442-1.1324l3.0439-12.925h-3.4682c-1.32322 0-2.2817-1.262-1.92659-2.5367L11.69 2.46328zM20.6393 3h-7.0227l-3.3 11.8458h3.4682c1.2898 0 2.2424 1.2029 1.9467 2.4584l-1.9803 8.4091 10.2175-13.2779h-4.9211c-.3182 0-.6174-.1514-.8058-.4078-.1884-.2564-.2436-.5871-.1486-.8908L20.6393 3z"
          fill="#000"
          fillRule="evenodd"
        ></path>
      </symbol>
      <symbol id="info-xs" viewBox="0 0 16 16">
        <path
          clipRule="evenodd"
          d="M8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2ZM8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0ZM8 13C7.44771 13 7 12.5523 7 12V8C7 7.44772 7.44771 7 8 7C8.55228 7 9 7.44772 9 8V12C9 12.5523 8.55228 13 8 13ZM8 3.5C7.44771 3.5 7 3.94772 7 4.5C7 5.05228 7.44771 5.5 8 5.5C8.55228 5.5 9 5.05228 9 4.5C9 3.94772 8.55228 3.5 8 3.5Z"
          fillRule="evenodd"
        />
      </symbol>
    </defs>
  </svg>
);

import { ComponentPropsWithRef, useEffect, useState } from 'react';

import { Textfield as InnerTextfield } from '@lichtblick/ui-components/molecules/Textfield/Textfield';

type TextfieldProps = Omit<
  ComponentPropsWithRef<typeof InnerTextfield>,
  'onChange' | 'value' | 'onBlur' | 'onFocus' | 'onInvalid' | 'statusLabel'
> & {
  forcedError?: boolean;
  initialValue?: string;
  resetForcedError?: () => void;
};

export const Textfield: React.FC<TextfieldProps> = ({ forcedError, resetForcedError, ...props }) => {
  const [value, setValue] = useState<string>();
  const [isInvalidValue, setInvalidValue] = useState(false);
  const [isMissingValue, setMissingValue] = useState(false);
  const [isFocussed, setFocussed] = useState(false);

  useEffect(() => {
    setValue(props.initialValue);
  }, [props.initialValue]);

  return (
    <InnerTextfield
      {...props}
      onBlur={(e) => {
        setFocussed(false);
        setInvalidValue(!e.target.validity.valid && !e.target.validity.valueMissing);
        setMissingValue(e.target.validity.valueMissing);
      }}
      onChange={(e) => {
        resetForcedError?.();
        setInvalidValue(false);
        setValue(e.currentTarget.value);
      }}
      onFocus={() => {
        setFocussed(true);
      }}
      onInvalid={(e) => {
        e.preventDefault();
        setInvalidValue(!e.currentTarget.validity.valid && !e.currentTarget.validity.valueMissing);
        setMissingValue(e.currentTarget.validity.valueMissing);
      }}
      statusLabel={
        isMissingValue
          ? 'Hier fehlt noch eine Angabe'
          : isInvalidValue || forcedError
            ? 'Hier stimmt was nicht.'
            : undefined
      }
      statusType={!isFocussed && (isInvalidValue || isMissingValue || forcedError) ? 'error' : 'default'}
      value={value}
    />
  );
};

import classNames from 'classnames';
import { ElementType, FC, ReactNode } from 'react';

import styles from './headline.module.scss';

/**
 * Responsive size classes:
 * https://www.figma.com/file/A0m8tFZk3yHpbiCtmlCXfb/%5BPhoton%5D-Typography-(Web)?node-id=2%3A32&t=jtIHhBKNgXKstYE0-1
 */
export type HeadlineSizeClass = 's' | 'm' | 'l';

export type HeadlineProps = {
  align?: 'left' | 'center' | 'right';
  children?: ReactNode;
  className?: string;
  renderAs?: ElementType | keyof JSX.IntrinsicElements;
  /**
   * L = mobile 32px | desktop 64px;
   * M = mobile 26px | desktop 36px;
   * S = mobile 20px | desktop 24px;
   */
  size?: HeadlineSizeClass;
};

export const Headline: FC<HeadlineProps> = ({
  align = 'left',
  children,
  className,
  renderAs: Tag = 'span',
  size = 'm',
}) => (
  <Tag className={classNames('headline', styles[`headline-${size}`], styles[`align-${align}`], className)}>
    {children}
  </Tag>
);

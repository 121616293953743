import { FC } from 'react';

import { Spacing } from '@lichtblick/theme';
import { AppBadges, Box } from '@lichtblick/ui-components';

import { assertExhaustive } from '../../helpers/assertExhaustive';
import { OStageSplitType } from '../../types/storyblok';
import { getColor, getTheme } from '../../utils';
import { AImage } from '../AImage/AImage';
import { AVideo } from '../AVideo/AVideo';
import { MAnimatedIllustration } from '../MAnimatedIllustration/MAnimatedIllustration';
import { MTextIntro } from '../MTextIntro';
import { GridColumn, GridContainer, GridRow } from '../shared';

const MediaContent: FC<{ content: OStageSplitType['media'] }> = ({ content: contentList }) => {
  if (!contentList?.[0]) {
    return null;
  }

  const content = contentList[0];

  switch (content.component) {
    case 'a-image':
      return <AImage {...content} />;
    case 'a-video':
      return <AVideo {...content} />;
    case 'm-animated-illustration':
      return <MAnimatedIllustration {...content} />;
    default:
      assertExhaustive(content);
  }
};

export const OStageSplit: FC<OStageSplitType> = ({
  appStoreUrl,
  defineSubheadlineAsH1: hasSubheadlineAsH1,
  media,
  playStoreUrl,
  textIntro,
  theme,
}) => (
  <Box
    alignItems="center"
    backgroundColor={getColor(theme)}
    data-testid="stage-full"
    display="flex"
    gap={Spacing.Xs}
    pxd={Spacing.Xl}
    pyd={Spacing.Xl}
    pym={Spacing.S}
  >
    <GridContainer>
      <GridRow $isAlignedCenter={true} $isReversed={true}>
        {media?.[0] && (
          <GridColumn $width={4}>
            <MediaContent content={media} />
          </GridColumn>
        )}

        <GridColumn $width={8}>
          {textIntro?.[0] && (
            <MTextIntro
              {...textIntro[0]}
              accentColor={getTheme(theme).primary}
              buttonVariant="primary"
              headlineProps={{ renderAs: hasSubheadlineAsH1 ? 'div' : 'h1', size: 'L' }}
              textProps={{ size: 'S', $isOStageSplit: true }}
            />
          )}

          {(appStoreUrl || playStoreUrl) && (
            <AppBadges appStoreUrl={appStoreUrl ?? undefined} playStoreUrl={playStoreUrl ?? undefined} />
          )}
        </GridColumn>
      </GridRow>
    </GridContainer>
  </Box>
);

import { FC } from 'react';

import { buildUrl } from '../../helpers/links';
import { MLogoItemType } from '../../types/storyblok';
import { AImage } from '../AImage/AImage';

export const MLogoItem: FC<MLogoItemType> = ({
  image,
  linkAsset,
  linkEntry,
  linkUrl,
  openAsDownload: shouldOpenAsDownload,
  openInNewTab: shouldOpenInNewTab,
}) => {
  const url = linkUrl || (linkEntry && buildUrl(linkEntry)) || linkAsset?.filename;

  if (!url) {
    return <AImage {...image[0]} />;
  }

  return (
    <a download={shouldOpenAsDownload} href={url} rel="noreferrer" target={shouldOpenInNewTab ? '_blank' : '_self'}>
      <AImage {...image[0]} />
    </a>
  );
};

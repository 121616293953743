'use client';

import classNames from 'classnames';
import { FC, MouseEventHandler, PropsWithChildren, ReactNode } from 'react';

import Link from '@lichtblick/link';
import { TrackingData, useTracker } from '@lichtblick/tracker';

import styles from './teaser-item.module.scss';

import { Chip } from '../../atoms/chip/chip';
import { Headline } from '../../atoms/headline/headline';
import { Text } from '../../atoms/text/text';
import { Colors } from '../../types/colors';

type LinkProps = {
  href?: string;
  isDownloadLink?: boolean;
  shouldOpenInNewTab?: boolean;
  title: string;
};

export type TeaserItemProps = PropsWithChildren<{
  backgroundColor?: Colors;
  chip?: string;
  className?: string;
  headline?: string;
  link?: LinkProps;
  media?: ReactNode;
  onClick?: MouseEventHandler<HTMLElement>;
  text?: string | ReactNode;
  textOutside?: boolean;
  tracking?: TrackingData;
}>;

export const TeaserItem: FC<TeaserItemProps> = ({
  backgroundColor = 'White',
  chip,
  className,
  headline,
  link,
  media,
  onClick,
  text,
  textOutside = false,
  tracking,
}) => {
  const { trackCustomEvent } = useTracker();

  const trackEvent = (trackingData?: TrackingData) => {
    trackCustomEvent(trackingData);

    if (link?.href?.includes('onelink')) {
      trackCustomEvent(
        {
          eventName: 'app_link',
          customProps: {
            link_type: 'Onelink',
          },
        },
        undefined,
        false,
      );
    }
  };

  const content = (
    <div className={classNames(styles.content, textOutside && styles['content-text-outside'])}>
      {chip && <Chip className={styles.chip}>{chip}</Chip>}
      {headline && (
        <Headline className={styles.headline} size="s">
          {headline}
        </Headline>
      )}
      {text && <Text className={styles.text}>{text}</Text>}
      {!textOutside && link?.title && (
        <div className={styles['text-cta']}>
          <Text bold className={styles['text-cta-text']} size="l">
            {link?.title}
          </Text>
        </div>
      )}
    </div>
  );

  return (
    <Link
      aria-label={link?.title}
      className={classNames('teaser-item', styles.teaser, styles[`teaser-bg-${backgroundColor}`], className)}
      data-testid="teaser-item"
      download={link?.isDownloadLink}
      href={link?.href ?? '#'}
      onClick={(event) => {
        trackEvent(tracking);
        onClick?.(event);
      }}
      target={link?.shouldOpenInNewTab ? '_blank' : undefined}
    >
      <div
        className={classNames(
          styles.container,
          !textOutside && backgroundColor && styles[`container-bg-${backgroundColor}`],
        )}
      >
        {media && <div className={classNames(styles.media)}>{media}</div>}
        {!textOutside && content}
      </div>
      {textOutside && content}
    </Link>
  );
};

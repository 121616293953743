import { FC } from 'react';

import { AccentText, AccordionHeader, AccordionTrigger } from '@lichtblick/ui-components';

import { CollapsibleContent, Content, HeaderContent, Icon, IconWrapper, Root } from './MAccordionItem.styles';

import { RichText } from '../../helpers/RichText';
import { MAccordionItemType } from '../../types/storyblok';

export const MAccordionItem: FC<MAccordionItemType & { isThemeFadedGray?: boolean }> = ({
  isThemeFadedGray,
  text,
  title,
}) => (
  <Root $isThemeFadedGray={isThemeFadedGray ?? false} data-testid="accordion-item" value={title ?? ''}>
    <AccordionHeader asChild>
      <AccordionTrigger>
        <HeaderContent>
          <IconWrapper>
            <Icon name="PlusIconS" />
          </IconWrapper>
          <AccentText>{title}</AccentText>
        </HeaderContent>
      </AccordionTrigger>
    </AccordionHeader>
    <Content>
      <CollapsibleContent>
        <RichText richText={text} />
      </CollapsibleContent>
    </Content>
  </Root>
);

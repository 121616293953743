import { FC } from 'react';

import { Spacing } from '@lichtblick/theme';
import { Box, Text } from '@lichtblick/ui-components';

import { StyledHeadline, StyledText, StyledUl } from './OText.styles';

import { ElementMappers, RichText } from '../../helpers/RichText';
import { OTextType } from '../../types/storyblok';
import { getColor, getSpacing } from '../../utils';
import { AnchorMark, GridColumn, GridContainer, GridRow } from '../shared';

const elementMappers: Partial<ElementMappers> = {
  ul: ({ children }) => <StyledUl>{children}</StyledUl>,
  p: ({ children }) => <StyledText renderAs="p">{children}</StyledText>,
};

export const OText: FC<OTextType> = ({
  anchorId,
  headline,
  paddingBottomDesktop,
  paddingBottomMobile,
  paddingTopDesktop,
  paddingTopMobile,
  richText,
  theme,
}) => (
  <Box
    backgroundColor={getColor(theme)}
    data-testid="text"
    pbd={getSpacing(paddingBottomDesktop) ?? Spacing.Xl}
    pbm={getSpacing(paddingBottomMobile) ?? Spacing.L}
    ptd={getSpacing(paddingTopDesktop) ?? Spacing.Xl}
    ptm={getSpacing(paddingTopMobile) ?? Spacing.L}
  >
    {anchorId && <AnchorMark anchorId={anchorId} />}
    <GridContainer>
      <GridRow>
        <GridColumn $push={2} $width={8}>
          {headline && <StyledHeadline renderAs="h2">{headline}</StyledHeadline>}
          {richText && (
            <Text renderAs="div" size="M">
              <RichText elementMappers={elementMappers} richText={richText} />
            </Text>
          )}
        </GridColumn>
      </GridRow>
    </GridContainer>
  </Box>
);

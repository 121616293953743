import { FC, useMemo } from 'react';

import { Number1IconXl } from '@lichtblick/icons/svg/number-1/number-1-xl';
import { Number2IconXl } from '@lichtblick/icons/svg/number-2/number-2-xl';
import { Number3IconXl } from '@lichtblick/icons/svg/number-3/number-3-xl';
import { Number4IconXl } from '@lichtblick/icons/svg/number-4/number-4-xl';
import { Spacing } from '@lichtblick/theme';
import { Headline, Box } from '@lichtblick/ui-components';

import { Steps, Step } from './Steps';

import { ElementMappers, RichText } from '../../../helpers/RichText';
import { FourStepsType } from '../../../types';
import { getColor } from '../../../utils';
import { AButton } from '../../AButton';
import { Section, Container } from '../shared';

const icons = [Number1IconXl, Number2IconXl, Number3IconXl, Number4IconXl];

const titleElementMappers: Partial<ElementMappers> = {
  p: ({ children }) => (
    <Headline renderAs="h2" size="M">
      {children}
    </Headline>
  ),
};

export const FourSteps: FC<FourStepsType> = ({ button, steps, theme = 'white', title }) => {
  const stepsElementMappers = useMemo<Partial<ElementMappers>>(
    () => ({
      ol: ({ children }) => <Steps>{children}</Steps>,
      li: ({ children, i }) => {
        return children ? <Step backgroundColor={getColor(theme)} icon={icons[i]} text={children} /> : null;
      },
    }),
    [theme],
  );

  return (
    <Section backgroundColor={getColor(theme)}>
      <Container maxWidth={600} textAlign="center">
        {title && <RichText elementMappers={titleElementMappers} richText={title} />}
      </Container>
      <Container>
        {steps && <RichText elementMappers={stepsElementMappers} richText={steps} />}
        {Boolean(button?.length) && (
          <Box display="flex" justifyContent="center" py={Spacing.S}>
            <AButton {...button[0]} />
          </Box>
        )}
      </Container>
    </Section>
  );
};

import styled, { css } from '@lichtblick/styled';
import { Colors, MediaMediumAndAbove, MediaBelowMedium, Spacing } from '@lichtblick/theme';
import { AppBadges, Box, StyledHeadline, StyledText } from '@lichtblick/ui-components';

import { ColorKey, getColor, getTheme } from '../../utils';
import { MTextIntro } from '../MTextIntro';
import { GridContainer } from '../shared';

export const Background = styled.div<{ $isEmbed: boolean }>`
  height: 100%;
  inset: 0;
  position: ${({ $isEmbed }) => ($isEmbed ? 'static' : 'absolute')};
  width: 100%;

  .picture-mobile img {
    aspect-ratio: ${({ $isEmbed }) => ($isEmbed ? '5 / 2' : 'initial')};
  }

  ${MediaMediumAndAbove} {
    position: absolute;
  }
`;

export const Stage = styled(Box)<{ $isEmbed: boolean }>`
  --overlay-color: ${({ backgroundColor }) => backgroundColor};
  --overlay-angle: 0;

  align-items: flex-end;
  display: flex;
  min-height: 32rem;

  ${({ $isEmbed }) =>
    $isEmbed &&
    css`
      ${MediaBelowMedium} {
        flex-direction: column;
        min-height: 0;
        padding: 0;
        color: ${Colors.Black};
        background-color: ${Colors.FadedGray};

        &::after {
          display: none;
        }
      }
    `}

  overflow: hidden;
  padding: ${Spacing.S} 0;
  position: relative;

  ${MediaMediumAndAbove} {
    --overlay-angle: 90deg;
    align-items: center;
    height: calc(100vh - 11rem);
    margin-bottom: ${({ $isEmbed }) => ($isEmbed ? '-6.5rem' : 0)};
    max-height: 45rem;
    padding: ${Spacing.Xl} 0;
  }

  &::after {
    background: linear-gradient(var(--overlay-angle), var(--overlay-color), transparent 80%);
    content: '';
    height: 100%;
    left: 0;
    opacity: 0.35;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

export const StyledMTextIntro = styled(MTextIntro)<{ $isEmbed: boolean }>`
  ${MediaBelowMedium} {
    padding: ${({ $isEmbed }) => ($isEmbed ? `${Spacing.Xs} 0 ${Spacing.Xxs}` : 0)};
  }
`;

export const StyledGridContainer = styled(GridContainer)`
  position: relative;
  width: 100%;
  z-index: 1;
`;

export const StyledAppBadges = styled(AppBadges)`
  margin-top: ${Spacing.Xxs};
`;

export const Badge = styled.div<{ $theme: ColorKey }>`
  background-color: ${({ $theme }) => getColor($theme)};
  color: ${({ $theme }) => getTheme($theme).primary};
  position: absolute;
  top: 1rem;
  left: 1rem;
  width: 6.25rem;
  height: 6.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 50%;

  ${MediaMediumAndAbove} {
    width: 8.25rem;
    height: 8.25rem;
    top: 4rem;
    right: 4rem;
    left: unset;
  }

  ${StyledHeadline} {
    line-height: 110%;
    font-size: 2.5rem;
  }

  ${StyledText} {
    line-height: 110%;
    font-size: 0.875rem;
  }
`;

import { Colors } from './colors';

export const light = {
  primary: Colors.Black,
  contrast: Colors.White,
};

export const dark = {
  primary: Colors.White,
  contrast: Colors.Black,
};

export const darkColors = [
  Colors.Black,
  Colors.DarkRed,
  Colors.Berry,
  Colors.DarkBerry,
  Colors.Aqua,
  Colors.DarkAqua,
  Colors.Smoke,
  Colors.DarkSmoke,
  Colors.Green,
  Colors.DarkGreen,
  Colors.Moss,
  Colors.DarkMoss,
  Colors.DarkGray,
];

export const themes = {
  dark,
  light,
};

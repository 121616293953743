import Script from 'next/script';
import { FC } from 'react';

import { OWallboxConfiguration } from './OWallboxConfiguration';
import { OWallboxConfiguratorIcons } from './OWallboxConfiguratorIcons';

import { buildUrl } from '../../helpers/links';
import { OWallboxConfiguratorType } from '../../types/storyblok';
import { AnchorMark } from '../shared';

const OWallboxConfigurator: FC<OWallboxConfiguratorType> = ({
  _uid,
  anchorId,
  appConfig,
  checkoutUrl,
  defineHeadlineAsH1: isHeadlineAsH1Defined,
  directSalesPartnerCode,
  hideAmount: isAmountHidden,
  moreInfoLinkUrl,
  powerCloudCampaignId,
  productCode,
  theme,
  useDirectSalesPartnerCodeSitewide: isDirectSalesParnterCodeUsedSitewide,
  wallboxType,
}) => {
  const uid = `wallbox-configurator-${_uid}`;
  const configId = `wallbox-config-${_uid}`;
  const appContentId = appConfig?.[0]?.contents?.[0]?._uid;

  return (
    <>
      {/* temporary solution until car calculator is rewritten for NextJS */}
      <Script async src="https://static.lichtblick.de/web-components/base.4a4201e56a0f4b1a9061bab7b83e9c09.js" />
      <Script
        async
        src="https://static.lichtblick.de/web-components/toggle_switch.af4e12a42eaae4d46b497883a8cba60c.js"
      />
      <Script
        async
        src="https://static.lichtblick.de/web-components/color_selector.3050c6ad43d2c44efab5035895651951.js"
      />
      <OWallboxConfiguration configId={configId} id={appContentId ?? ''} />
      <OWallboxConfiguratorIcons />
      <div data-testid="wallbox-configurator">
        {anchorId && <AnchorMark anchorId={anchorId} />}
        <div
          data-campaign-id={powerCloudCampaignId}
          data-checkout-url={checkoutUrl && buildUrl(checkoutUrl)}
          data-config-id={configId}
          data-define-headline-as-h1={isHeadlineAsH1Defined}
          data-hide-amount={isAmountHidden}
          data-more-info-link-url={moreInfoLinkUrl}
          data-product-code={productCode}
          data-store-vp-code={isDirectSalesParnterCodeUsedSitewide}
          data-theme={theme}
          data-vp-code={directSalesPartnerCode}
          data-wallbox-type={wallboxType}
          id={uid}
        />
      </div>
    </>
  );
};

export default OWallboxConfigurator;

export const getPathname = (route: string) => {
  const url = new URL(route, 'https://www.lichtblick.de');

  let pathname = url.pathname;

  if (!pathname.endsWith('/')) {
    pathname += '/';
  }

  return pathname;
};

import { FC } from 'react';

import { Container } from '@lichtblick/ui-components';

import { OFootnotesRoot } from './OFootnotes.styles';

import { MFootnoteType } from '../../types/storyblok';
import { MFootnote } from '../MFootnote/MFootnote';

export type OFootnotesProps = {
  footnotes?: MFootnoteType[];
  theme?: 'light' | 'dark';
};

export const OFootnotes: FC<OFootnotesProps> = ({ footnotes, theme }) => {
  if (!footnotes?.length) {
    return null;
  }

  return (
    <OFootnotesRoot $theme={theme}>
      <Container as={'div'}>
        {footnotes?.map((footnote, index: number) => (
          <MFootnote {...footnote} index={index + 1} key={footnote._uid} theme={theme} />
        ))}
      </Container>
    </OFootnotesRoot>
  );
};

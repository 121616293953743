import { getPathname } from './get-pathname';
import { Routes } from './link-context';

export const isExternalRoute = (route: string, routes: Routes): boolean => {
  const pathname = getPathname(route);

  if (
    routes.externalRoutes?.absolute.includes(pathname) ||
    routes.externalRoutes?.catchAll.some((url) => pathname.startsWith(url))
  ) {
    return true;
  }

  if (routes.internalRoutes?.absolute.length || routes.internalRoutes?.catchAll.length) {
    return (
      !routes.internalRoutes.absolute.includes(pathname) &&
      !routes.internalRoutes.catchAll.some((url) => pathname.startsWith(url))
    );
  }

  return false;
};

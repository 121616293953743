import Link from '@lichtblick/link';
import styled, { css } from '@lichtblick/styled';
import { BorderRadius, Colors, MediaMediumAndAbove, Spacing } from '@lichtblick/theme';
import { Headline, focusRing } from '@lichtblick/ui-components';

export const StyledHeadline = styled(Headline)`
  margin-bottom: ${Spacing.Xxs};
`;

export const TeaserListItem = styled(Link)`
  position: relative;
  display: block;
  padding: ${Spacing.Xs};
  border: 1px solid ${Colors.Gray};
  border-radius: ${BorderRadius.S};
  transition: background-color 0.1s;

  ${MediaMediumAndAbove} {
    padding: ${Spacing.Xs};
  }

  &:hover {
    background: color-mix(in srgb, ${Colors.Black} 11%, transparent);
  }

  &:focus-visible {
    ${focusRing}
  }
`;

export const Meta = styled.div<{ $isExternalLink: boolean }>`
  color: ${Colors.DarkGray};
  display: block;
  font-size: 0.75rem;
  line-height: 1.7;

  ${({ $isExternalLink }) =>
    $isExternalLink &&
    css`
      &::after {
        content: '\\2197';
        position: absolute;
        right: 0;
        color: ${Colors.DarkGray};
      }
    `}

  width: 100%;
`;

export const MetaValue = styled.span`
  &::before {
    content: '|';
    margin-inline: ${Spacing.Xs};
  }

  &:first-of-type::before {
    content: none;
  }
`;

import { SolarIconM } from '@lichtblick/icons/svg/solar/solar-m';
import { Colors } from '@lichtblick/theme';
import { useTracker } from '@lichtblick/tracker';
import { BoxCTA, Text } from '@lichtblick/ui-components';

import { CalculatorLayout } from './CalculatorLayout';
import { StyledCalculatorContent } from './CalculatorLayout.styles';

import { buildUrl } from '../../helpers/links';
import { SolarTeaserType } from '../../types';

type SolarTeaserProps = SolarTeaserType & Pick<CalculatorLayout, 'isActionTriggerDisabled'>;

export const SolarTeaser: React.FC<SolarTeaserProps> = ({
  _uid,
  buttonLink,
  buttonText,
  chip,
  headline,
  isActionTriggerDisabled,
  text,
}) => {
  const { trackCustomEvent } = useTracker();

  return (
    <CalculatorLayout
      chip={chip}
      headerColor={Colors.DarkOrange}
      headline={headline}
      icon={<SolarIconM />}
      id={_uid}
      isActionTriggerDisabled={isActionTriggerDisabled}
    >
      <StyledCalculatorContent>
        <Text>{text}</Text>
        <BoxCTA
          as="a"
          data-testid="solar-teaser-button"
          href={buildUrl(buttonLink) ?? ''}
          isWide
          onClick={() =>
            trackCustomEvent({
              eventName: 'tariff_calculator_show_tariffs',
              customProps: {
                product: 'Solar',
              },
            })
          }
        >
          {buttonText}
        </BoxCTA>
      </StyledCalculatorContent>
    </CalculatorLayout>
  );
};

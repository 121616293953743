import { CssVariable, NextFontWithVariable } from 'next/dist/compiled/@next/font';
import localFont from 'next/font/local';

export type FontTypes = 'text' | 'headlines';

const headlineVariable = '--font-headline';
const textVariable = '--font-text';

const headlineFontLoader = localFont({
  src: '../assets/fonts/officina.woff2',
  adjustFontFallback: 'Arial',
  weight: '500',
  variable: '--font-headline',
});

const textFontLoader = localFont({
  src: [
    { path: '../assets/fonts/Inter-roman.var-subset.woff2', weight: '400', style: 'normal' },
    { path: '../assets/fonts/Inter-italic.var-subset.woff2', weight: '400', style: 'italic' },
    { path: '../assets/fonts/Inter-roman.var-subset.woff2', weight: '600', style: 'normal' },
    { path: '../assets/fonts/Inter-italic.var-subset.woff2', weight: '600', style: 'italic' },
  ],
  variable: '--font-text',
});

export type NextFontWithVariableName = NextFontWithVariable & {
  variableName: CssVariable;
};

export const fontStyles: Record<FontTypes, NextFontWithVariableName> = {
  headlines: {
    ...headlineFontLoader,
    variableName: headlineVariable,
  },
  text: {
    ...textFontLoader,
    variableName: textVariable,
  },
};

export const fontVariables = `${headlineFontLoader.variable} ${textFontLoader.variable}`;

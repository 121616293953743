import Link from '@lichtblick/link';
import styled, { FlattenSimpleInterpolation, css, keyframes } from '@lichtblick/styled';
import { Colors, MediaMediumAndAbove, Opacities, Spacing } from '@lichtblick/theme';

import { focusRing } from '../shared';

export type BoxCTAVariant = 'primary' | 'secondary' | 'destructive';

type StyledBoxCTAProps = {
  $isSlim?: boolean;
  $isWide?: boolean;
  $variant: BoxCTAVariant;
};

const PrimaryBoxCTAStyles = css`
  background-color: ${Colors.Orange};
  color: ${Colors.Black};

  &:hover {
    background-color: color-mix(in srgb, currentColor 11%, ${Colors.Orange});
  }
`;

const SecondaryBoxCTAStyles = css`
  border: 1px solid;

  &:hover {
    background-color: color-mix(in srgb, currentColor 11%, transparent);
  }
`;

const DestructiveBoxCTAStyles = css`
  background-color: ${Colors.Red};
  color: ${Colors.White};

  &:hover {
    background-color: color-mix(in srgb, currentColor 11%, ${Colors.Red});
  }
`;

const BoxCTAStyleMap: { [key in BoxCTAVariant]: FlattenSimpleInterpolation } = {
  primary: PrimaryBoxCTAStyles,
  secondary: SecondaryBoxCTAStyles,
  destructive: DestructiveBoxCTAStyles,
};

export const StyledBoxCTA = styled(Link)<StyledBoxCTAProps>`
  appearance: none;
  background-color: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  display: inline-block;
  font: inherit;
  font-size: ${({ $isSlim }) => ($isSlim ? 0.75 : 1)}rem;
  font-weight: 600;
  flex-grow: 0;
  line-height: 1.7;
  text-align: center;
  width: ${({ $isWide }) => ($isWide ? '100%' : 'auto')};
  border-radius: ${Spacing.L};
  transition: 0.1s;

  ${MediaMediumAndAbove} {
    font-size: ${({ $isSlim }) => ($isSlim ? 0.875 : 1)}rem;
  }

  &:disabled,
  &[disabled] {
    opacity: ${Opacities.DisabledCustomIcon};
    pointer-events: none;

    filter: grayscale(100%);
  }

  outline: none;
  padding: ${({ $isSlim }) => ($isSlim ? '0.25rem 1rem' : '0.5rem 1rem')};

  &:focus-visible {
    ${focusRing};
  }

  &:hover {
    // cannot be animated
    // background-image: linear-gradient(0deg, rgba(0, 0, 0, 11%), rgba(0, 0, 0, 11%));
  }

  &:active {
    outline: none;
    transform: scale(0.98);
  }

  ${({ $variant }) => BoxCTAStyleMap[$variant]}
`;

export const StyledBoxCTAIconWrapper = styled.span`
  position: relative;
  /* stylelint-disable-next-line meowtec/no-px */
  top: -1px;

  & > * {
    vertical-align: middle;
  }
`;

const loadingKeyframes = keyframes`
  0% {
    content: '';
  }
  25% {
    content: '.';
  }
  50% {
    content: '..';
  }
  75% {
    content: '...';
  }
`;

export const StyledBoxCTAText = styled.span<{ $isLoading?: boolean }>`
  ${({ $isLoading }) =>
    $isLoading &&
    css`
      &::after {
        animation: ${loadingKeyframes} 1.5s linear infinite;
        content: '';
        display: inline-block;
        text-align: left;
        width: 1rem;
      }
    `}

  position: relative;

  ${StyledBoxCTAIconWrapper} + & {
    margin-left: ${Spacing.Xxs};
  }
`;

import { FC, useState } from 'react';

import { Headline, Text } from '@lichtblick/ui-components';

import {
  StyledCard,
  StyledFooter,
  StyledHeader,
  StyledTariffIcon,
  StyledCustomerSwitchWrapper,
  StyledCustomerSwitchInputWrapper,
  StyledCustomerSwitchButton,
  DataListContainer,
  DataList,
} from './MEAutoTariffCard.styles';

import { MEautoTariffCardType } from '../../types/storyblok';
import { AButton } from '../AButton';
import { AImage } from '../AImage';
import { AListItem } from '../AListItem';

export const MEAutoTariffCard: FC<MEautoTariffCardType> = ({
  customerSwitchLabel,
  existingCustomerActionButton,
  existingCustomerHeadline,
  existingCustomerInfoDialogButton,
  existingCustomerList,
  existingCustomerSubheadline,
  icon,
  newCustomerActionButton,
  newCustomerHeadline,
  newCustomerInfoDialogButton,
  newCustomerList,
  newCustomerSubheadline,
}) => {
  const [isExistingCustomer, setIsExistingCustomer] = useState(false);

  const list = isExistingCustomer ? existingCustomerList?.[0]?.items : newCustomerList?.[0]?.items;

  const dialogButton = isExistingCustomer ? existingCustomerInfoDialogButton?.[0] : newCustomerInfoDialogButton?.[0];
  const actionButton = isExistingCustomer ? existingCustomerActionButton?.[0] : newCustomerActionButton?.[0];

  return (
    <StyledCard>
      <StyledHeader>
        {Boolean(icon?.[0]) && (
          <StyledTariffIcon>
            <AImage {...icon[0]} />
          </StyledTariffIcon>
        )}

        <Headline renderAs="h3" size="S">
          {(isExistingCustomer ? existingCustomerHeadline : newCustomerHeadline) ?? ''}
        </Headline>

        <Headline className="subheadline" renderAs="h4" size="S">
          {(isExistingCustomer ? existingCustomerSubheadline : newCustomerSubheadline) ?? ''}
        </Headline>
      </StyledHeader>

      <div>
        <StyledCustomerSwitchWrapper>
          <Text className="customer-switch-label" isBold renderAs="h5" size="Xs">
            {customerSwitchLabel}
          </Text>

          <StyledCustomerSwitchInputWrapper $rightActive={!isExistingCustomer}>
            <StyledCustomerSwitchButton $active={isExistingCustomer} onClick={() => setIsExistingCustomer(true)}>
              Ja
            </StyledCustomerSwitchButton>

            <StyledCustomerSwitchButton $active={!isExistingCustomer} onClick={() => setIsExistingCustomer(false)}>
              Nein
            </StyledCustomerSwitchButton>
          </StyledCustomerSwitchInputWrapper>
        </StyledCustomerSwitchWrapper>

        <DataListContainer>
          {Boolean(list?.length) && (
            <DataList>{list?.map((props) => <AListItem key={props._uid} {...props}></AListItem>)}</DataList>
          )}
        </DataListContainer>
      </div>

      <StyledFooter>
        {dialogButton && <AButton hasNoIcon {...dialogButton} isSlim variant="link" />}

        {actionButton && <AButton {...actionButton}>{actionButton.text}</AButton>}
      </StyledFooter>
    </StyledCard>
  );
};

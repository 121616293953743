import Head from 'next/head';
import { FC, useEffect } from 'react';

import { GOOGLE_ANALYTICS_NAME, useConsent } from '@lichtblick/consent';

import { assertExhaustive } from '../../helpers/assertExhaustive';
import { WithMetaData } from '../../helpers/templateMapper';
import { useTemplateTracker } from '../../hooks';
import { StoryblokStoryType, TNotificationType } from '../../types/storyblok';
import { Layout } from '../Layout';
import { MFootnote } from '../MFootnote/MFootnote';
import { OColumns } from '../OColumns/OColumns';
import { ODownload } from '../ODownload/ODownload';
import { OStandoutText } from '../OStandoutText';
import { OSuccessPageContent } from '../OSuccessPageContent';
import { OSuccessRating } from '../OSuccessRating/OSuccessRating';
import { OTeaserGrid, OTeaserGridProps } from '../OTeaserGrid/OTeaserGrid';

const EEC_PURCHASE_SESSION_STORAGE_KEYS = ['EECpurchase', 'GA4EECpurchase', 'GA4EMobilityContactForm'];

const PartialContent: FC<{ content: Exclude<TNotificationType['partials'], undefined>[number] }> = ({ content }) => {
  if (!content) {
    return null;
  }

  switch (content.component) {
    case 'o-columns':
      return <OColumns {...content} />;
    case 'o-teaser-grid':
      return <OTeaserGrid {...(content as OTeaserGridProps)} />; // type will become obsolete with rsc refactor
    case 'o-download':
      return <ODownload {...content} />;
    case 'o-success-page-content':
      return <OSuccessPageContent />;
    case 'o-success-rating':
      return <OSuccessRating {...content} />;
    default:
      assertExhaustive(content);
  }
};

export const TNotification: FC<WithMetaData<StoryblokStoryType<TNotificationType>>> = ({
  content: {
    breadcrumbTitle,
    footnotes,
    metaTitle,
    partials,
    standoutText,
    trackingContentGroup,
    trackingContentGroup2,
    trackingContentGroup3,
    trackingPageArea,
    trackingPageType,
  },
  parentPages,
  slug,
}) => {
  useTemplateTracker({
    contentGroup: trackingContentGroup,
    contentGroup2: trackingContentGroup2,
    contentGroup3: trackingContentGroup3,
    pageArea: trackingPageArea ?? '',
    pageType: trackingPageType?.substring(2) ?? '',
    pageTitle: metaTitle ?? '',
  });

  const consent = useConsent(GOOGLE_ANALYTICS_NAME);
  const isTrackingEnabled = consent;

  useEffect(() => {
    const interval = setInterval(() => {
      const firedEvents = window.dataLayer.map((event) => event.event);

      if (!['consent_status', 'virtPath', 'Page Meta'].every((event) => firedEvents.includes(event))) {
        return;
      }

      clearInterval(interval);

      // due to timing issues the old checkouts store certain events in session storage
      // and rely on the order confirmation page to send them to the data layer
      EEC_PURCHASE_SESSION_STORAGE_KEYS.forEach((storageKey) => {
        const eventJson = sessionStorage.getItem(storageKey);

        if (isTrackingEnabled || !eventJson) {
          return;
        }

        try {
          const trackingEvent = JSON.parse(eventJson);

          if (trackingEvent && window.dataLayer) {
            window.dataLayer.push(trackingEvent);
            sessionStorage.removeItem(storageKey);
          }
        } catch (e) {
          //
        }
      });
    }, 100);
  }, [isTrackingEnabled]);

  return (
    <>
      <Head>
        <meta content="noindex, nofollow" name="robots" />
      </Head>
      <Layout breadcrumbTitle={breadcrumbTitle} parentPages={parentPages} slug={slug}>
        {standoutText?.[0] && <OStandoutText {...standoutText[0]} />}
        {partials?.map((item) => item && <PartialContent content={item} key={item._uid} />)}
        {footnotes?.map((item, index) => Boolean(item) && <MFootnote {...item} index={index} key={item._uid} />)}
      </Layout>
    </>
  );
};

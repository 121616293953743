import { SearchIconXs } from '@lichtblick/icons/svg/search/search-xs';
import styled, { PickTransient } from '@lichtblick/styled';
import { BorderRadius, Colors, MediaMediumAndAbove, MediaSmallAndAbove, Spacing } from '@lichtblick/theme';
import { Box, StyledBoxCTA } from '@lichtblick/ui-components';
import { Wrapper as TextfieldWrapper } from '@lichtblick/ui-components/molecules/Textfield/Textfield.styles';

import { SoftFunnelEntryType } from '../../../types';
import { getColor } from '../../../utils';

type PossibleColors = NonNullable<SoftFunnelEntryType['theme']>;

const foregroundColorMap: Record<PossibleColors, PossibleColors> = {
  'faded-gray': 'white',
  white: 'faded-gray',
};

export const Root = styled(Box)<PickTransient<SoftFunnelEntryType, 'theme'>>`
  background: ${({ $theme }) => ($theme ? getColor($theme) : Colors.White)};
`;

export const StageCard = styled.div<PickTransient<SoftFunnelEntryType, 'theme'>>`
  background: ${({ $theme }) => ($theme ? getColor(foregroundColorMap[$theme]) : Colors.White)};
  border-radius: ${BorderRadius.S};
  display: flex;
  flex-direction: column;
  list-style-type: none;
  /* stylelint-disable-next-line meowtec/no-px */
  max-width: 800px;
  margin: 0 auto;
  padding-block: ${Spacing.Xxs};

  ${MediaMediumAndAbove} {
    flex-direction: row;
  }
`;

export const FunnelForm = styled.form`
  width: 100%;
  padding-block: ${Spacing.Xs};
  padding-inline: ${Spacing.S};
  display: flex;
  flex-direction: column;
  gap: ${Spacing.S};
`;

export const ButtonWrapper = styled.div`
  flex-shrink: 1;
  flex-grow: 1;
  ${StyledBoxCTA} {
    width: 100%;
  }
`;

export const Controls = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: ${Spacing.Xxs};
  ${TextfieldWrapper} {
    flex-grow: 1;
  }

  ${MediaSmallAndAbove} {
    gap: ${Spacing.S};
  }
`;

export const StyledSearchIcon = styled(SearchIconXs)`
  margin-right: ${Spacing.Xs};
`;

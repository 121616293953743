import { Key } from 'react';

import { templateMapper } from '@lichtblick/contentful/helpers/templateMapper';

import { StaticProps } from '../pages/[[...slug]]';

export const ContentMapper = ({ parentPages, slug, story }: StaticProps, key?: Key) => {
  if (!story) {
    return null;
  }

  const { Component, props } = templateMapper({ ...story, parentPages, slug });

  return <Component key={key} {...props} />;
};

import Link from '@lichtblick/link';
import styled from '@lichtblick/styled';
import { MediaMediumAndAbove, MediaSmallAndAbove } from '@lichtblick/theme';

import { footerCellStyles } from './Footer.styles';

export const Root = styled.blockquote`
  ${footerCellStyles};

  ${MediaSmallAndAbove} {
    margin-right: 50%;
    width: 50%;
  }

  ${MediaMediumAndAbove} {
    margin-right: 16.6666%;
  }
`;

export const HomeLink = styled(Link)`
  display: block;
  margin-bottom: 1.75rem;
  min-height: 0;
  width: 12.75rem;

  ${MediaMediumAndAbove} {
    margin-bottom: 2rem;
    width: 16.5rem;
  }
`;

export const RichTextContainer = styled.blockquote``;

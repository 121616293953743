import { FC } from 'react';

import { StyledIcon, toIconName } from '@lichtblick/icons';
import { Modal } from '@lichtblick/ui-components';

import { ContentWrapper, StyledH6Headline, StyledHeadline, StyledText } from './OInfoDialog.styles';

import { assertExhaustive } from '../../helpers/assertExhaustive';
import { ElementMappers, RichText } from '../../helpers/RichText';
import { OInfoDialogType } from '../../types/storyblok';
import { getColor } from '../../utils';
import { AImage } from '../AImage/AImage';
import { AVideo } from '../AVideo/AVideo';
import { MAnimatedIllustration } from '../MAnimatedIllustration/MAnimatedIllustration';

export type OInfoDialogProps = OInfoDialogType & {
  isOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
  onSecondaryButtonClick: () => void;
};

const MediaContent: FC<{ content: OInfoDialogProps['media'] }> = ({ content: contentList }) => {
  if (!contentList?.[0]) {
    return null;
  }

  const content = contentList[0];

  switch (content.component) {
    case 'a-image':
      return <AImage {...content} />;
    case 'a-video':
      return <AVideo {...content} />;
    case 'm-animated-illustration':
      return <MAnimatedIllustration {...content} />;
    default:
      assertExhaustive(content);
  }
};

const elementMappers: Partial<ElementMappers> = {
  h3: ({ children }) => (
    <StyledHeadline renderAs={'div'} size="S">
      {children}
    </StyledHeadline>
  ),
  p: ({ children }) => <StyledText>{children}</StyledText>,
  h6: ({ children }) => <StyledH6Headline renderAs={'div'}>{children}</StyledH6Headline>,
};

export const OInfoDialog: FC<OInfoDialogProps> = ({
  ariaLabel,
  buttonText,
  headline,
  headlineIcon,
  headlineIconColor: shiftColor,
  isOpen,
  isTextCentered,
  media,
  onOpenChange,
  onSecondaryButtonClick,
  richText,
}) => (
  <Modal
    aria-label={ariaLabel}
    isOpen={isOpen}
    onOpenChange={onOpenChange}
    onSecondaryButtonClick={onSecondaryButtonClick}
    secondaryButtonLabel={buttonText ?? undefined}
  >
    <ContentWrapper isTextCentered={isTextCentered}>
      {Boolean(headline) && (
        <StyledHeadline renderAs={'div'} size="S">
          {headline}
          {headlineIcon && <StyledIcon name={toIconName(headlineIcon)} shiftColor={getColor(shiftColor)} />}
        </StyledHeadline>
      )}
      {Boolean(richText) && (
        <div>
          <RichText elementMappers={elementMappers} richText={richText} />
        </div>
      )}
      {media?.length === 1 && (
        <div>
          <MediaContent content={media} />
        </div>
      )}
    </ContentWrapper>
  </Modal>
);

import { FC } from 'react';

import { assertExhaustive } from '../../helpers/assertExhaustive';
import { useTemplateTracker } from '../../hooks';
import { StoryblokStoryType, THomeType } from '../../types/storyblok';
import { Layout } from '../Layout';
import { MNavigationSecondary } from '../MNavigationSecondary';
import { OAccordion } from '../OAccordion';
import { OAllInOneCalculator } from '../OAllInOneCalculator';
import { OCarousel } from '../OCarousel';
import { OColumns } from '../OColumns';
import { ODownload } from '../ODownload';
import { OEditorial } from '../OEditorial';
import { OFactGrid } from '../OFactGrid';
import { OFootnotes } from '../OFootnotes';
import { OForm } from '../OForm';
import { OIndex } from '../OIndex/OIndex';
import { OInfoteaser } from '../OInfoteaser';
import { OList } from '../OList';
import { OLogos } from '../OLogos';
import { OMedia } from '../OMedia';
import { ORoadToZero } from '../ORoadToZero/ORoadToZero';
import { OSnowflake } from '../OSnowflake';
import { OStageArticle } from '../OStageArticle/OStageArticle';
import { OStageFull } from '../OStageFull/OStageFull';
import { OStageNarrow } from '../OStageNarrow/OStageNarrow';
import { OStageSplit } from '../OStageSplit/OStageSplit';
import { OStandoutText } from '../OStandoutText';
import { OTable } from '../OTable';
import { OTariffComparison } from '../OTariffComparison';
import { OTeaserGrid, OTeaserGridProps } from '../OTeaserGrid/OTeaserGrid';
import { OTeaserGridArticles } from '../OTeaserGridArticles';
import { OTeaserList } from '../OTeaserList';
import { OText } from '../OText';
import { OWallboxConfigurator } from '../OWallboxConfigurator';

const StageContent: FC<{ contentList: THomeType['stage'] }> = ({ contentList }) => {
  if (!contentList?.[0]) {
    return null;
  }

  const content = contentList[0];

  switch (content.component) {
    case 'o-stage-full':
      return <OStageFull {...content} />;
    case 'o-stage-narrow':
      return <OStageNarrow {...content} />;
    case 'o-stage-split':
      return <OStageSplit {...content} />;
    case 'o-stage-article':
      return <OStageArticle {...content} />;
    default:
      assertExhaustive(content);
  }
};

const PartialContent: FC<{ content: Exclude<THomeType['partials'], undefined>[number]; index: number }> = ({
  content,
  index,
}) => {
  if (!content) {
    return null;
  }

  switch (content.component) {
    case 'o-accordion':
      return <OAccordion {...content} />;
    case 'o-carousel':
      return <OCarousel {...content} />;
    case 'o-columns':
      return <OColumns {...content} />;
    case 'o-editorial':
      return <OEditorial {...content} />;
    case 'o-fact-grid':
      return <OFactGrid {...content} />;
    case 'o-form':
      return <OForm {...content} />;
    case 'o-list':
      return <OList {...content} />;
    case 'o-logos':
      return <OLogos {...content} />;
    case 'o-media':
      return <OMedia {...content} index={index} />;
    case 'o-snowflake':
      return <OSnowflake {...content} />;
    case 'o-standout-text':
      return <OStandoutText {...content} />;
    case 'o-tariff-comparison':
      return <OTariffComparison {...content} />;
    case 'o-teaser-grid':
      return <OTeaserGrid {...(content as OTeaserGridProps)} />; // type will become obsolete with rsc refactor
    case 'o-teaser-list':
      return <OTeaserList {...content} />;
    case 'o-text':
      return <OText {...content} />;
    case 'o-wallbox-configurator':
      return <OWallboxConfigurator {...content} />;
    case 'o-table':
      return <OTable {...content} />;
    case 'o-infoteaser':
      return <OInfoteaser {...content} />;
    case 'o-teaser-grid-articles':
      return <OTeaserGridArticles {...content} />;
    case 'o-all-in-one-calculator':
      return <OAllInOneCalculator {...content} />;
    case 'o-download':
      return <ODownload {...content} />;
    case 'o-index':
      return <OIndex {...content} />;
    case 'o-road-to-zero':
      return <ORoadToZero {...content} />;
    default:
      assertExhaustive(content);
  }
};

export const THome: FC<StoryblokStoryType<THomeType>> = ({
  content: {
    footnotes,
    metaTitle,
    partials,
    secondaryNavigation,
    stage,
    trackingContentGroup,
    trackingContentGroup2,
    trackingContentGroup3,
  },
}) => {
  useTemplateTracker({
    contentGroup: trackingContentGroup,
    contentGroup2: trackingContentGroup2,
    contentGroup3: trackingContentGroup3,
    pageArea: 'Home',
    pageType: 'home',
    pageTitle: metaTitle ?? '',
  });

  return (
    <Layout>
      {secondaryNavigation?.length === 1 && <MNavigationSecondary {...secondaryNavigation[0]} />}
      <StageContent contentList={stage} />
      {partials?.map((item, index) => item && <PartialContent content={item} index={index} key={item._uid} />)}
      {Boolean(footnotes?.length) && <OFootnotes footnotes={footnotes} />}
    </Layout>
  );
};

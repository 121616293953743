import classNames from 'classnames';
import { FC } from 'react';

import { ChargingStationIconM } from '@lichtblick/icons/svg/charging-station/charging-station-m';
import { ElectricityIconM } from '@lichtblick/icons/svg/electricity/electricity-m';
import { GasIconM } from '@lichtblick/icons/svg/gas/gas-m';
import { HeatingEnergyIconM } from '@lichtblick/icons/svg/heating-energy/heating-energy-m';
import { SolarIconM } from '@lichtblick/icons/svg/solar/solar-m';
import { BoxCTA } from '@lichtblick/photon/atoms/box-cta/box-cta';
import { Text } from '@lichtblick/photon/atoms/text/text';
import { Colors } from '@lichtblick/photon/types/colors';

import styles from './MCalculatorBox.module.scss';

import { MCalculatorBoxType } from '../../types/storyblok';

type ConfigurationType = {
  background: Colors;
  icon: JSX.Element;
  label: string;
  title: string;
  url: string;
};

type ConfigurationsType = Record<MCalculatorBoxType['calculator'], ConfigurationType>;

const configurations: ConfigurationsType = {
  electricity: {
    title: 'ÖkoStrom',
    label: 'Tarife berechnen',
    background: 'DarkMoss',
    icon: <ElectricityIconM className={styles.icon} />,
    url: '/checkout/oekostrom/',
  },
  gas: {
    title: 'ÖkoGas',
    label: 'Tarife berechnen',
    background: 'LightAqua',
    icon: <GasIconM className={styles.icon} />,
    url: '/checkout/oekogas/',
  },
  heat: {
    title: 'WärmeStrom',
    label: 'Tarife berechnen',
    background: 'DarkBerry',
    icon: <HeatingEnergyIconM className={styles.icon} />,
    url: '/waermestrom-checkout/',
  },
  solar: {
    title: 'SolarPaket',
    label: 'Anlage berechnen',
    background: 'DarkSmoke',
    icon: <SolarIconM className={styles.icon} />,
    url: '/zuhause/solar/',
  },
  eMobility: {
    title: 'ÖkoStrom E-Auto',
    label: 'Tarife berechnen',
    background: 'LightMoss',
    icon: <ChargingStationIconM className={styles.icon} />,
    url: '/e-mobilitaet/e-auto-zuhause-laden/',
  },
};

type MCalculatorBoxProps = MCalculatorBoxType & {
  className?: string;
};

export const MCalculatorBox: FC<MCalculatorBoxProps> = ({ calculator, className }) => {
  const { background, icon, label, title, url } = configurations[calculator];

  return (
    <div className={classNames(styles['container'], styles[`container-bg-${background}`], className)}>
      <div className={styles['title']}>
        <Text bold size="l">
          {title}
        </Text>
        {icon}
      </div>

      <BoxCTA
        ariaLabel={label}
        as="a"
        className={styles.cta}
        data-dd-action-name={`Open calculator: ${calculator}`}
        data-testid="button-open-calculator"
        href={url}
        target="_blank"
      >
        {label}
      </BoxCTA>
    </div>
  );
};

import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { useTracker } from '@lichtblick/tracker';

export type TemplateTrackerProps = {
  contentGroup?: string;
  contentGroup2?: string;
  contentGroup3?: string;
  pageArea: string;
  pageTitle: string;
  pageType: string;
};

export const useTemplateTracker = ({
  contentGroup,
  contentGroup2,
  contentGroup3,
  pageArea,
  pageTitle,
  pageType,
}: TemplateTrackerProps) => {
  const router = useRouter();
  const { trackPageMetaEvent, trackVirtPathEvent } = useTracker();

  useEffect(() => {
    trackVirtPathEvent({
      virtPagePath: window.location.pathname,
      virtPageTitle: pageTitle,
    });
  }, [trackVirtPathEvent, router.asPath, pageTitle]);

  useEffect(() => {
    trackPageMetaEvent({
      contentGroup,
      contentGroup2,
      contentGroup3,
      pageArea,
      pageType,
    });
  }, [contentGroup, contentGroup2, contentGroup3, pageArea, pageType, trackPageMetaEvent, router.asPath]);
};

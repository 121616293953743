import { FC } from 'react';

import { Colors, darkColors } from '@lichtblick/theme';
import { Text } from '@lichtblick/ui-components';
import { HeadlineProps, LegacyButtonVariant, TextIntro, TextProps } from '@lichtblick/ui-components';

import { StyledHeadline } from './MTextIntro.styles';

import { ElementMappers, RichText } from '../../helpers/RichText';
import { MTextIntroType } from '../../types/storyblok';
import { AButton } from '../AButton';

export type MTextIntroProps = MTextIntroType & {
  accentColor?: Colors;
  buttonVariant?: LegacyButtonVariant;
  className?: string;
  headlineProps?: HeadlineProps;
  isCenteredDesktop?: boolean;
  isCenteredMobile?: boolean;
  textProps?: TextProps & { $isOStageSplit?: boolean };
};

const elementMappers: Partial<ElementMappers> = {
  h1: ({ children }) => (
    <StyledHeadline renderAs="h1" size="S">
      {children}
    </StyledHeadline>
  ),
  h3: ({ children }) => (
    <StyledHeadline renderAs="h3" size="S">
      {children}
    </StyledHeadline>
  ),
  p: ({ children }) => (
    <Text renderAs="p" size="M">
      {children}
    </Text>
  ),
};

export const MTextIntro: FC<MTextIntroProps> = ({
  accentColor = Colors.Black,
  button,
  buttonVariant = 'link',
  chip,
  className,
  headline,
  headlineProps,
  isCenteredDesktop,
  isCenteredMobile,
  richText,
  textProps,
}) => (
  <TextIntro
    chipProps={
      chip
        ? {
            label: chip,
            backgroundColor: accentColor,
            textColor: darkColors.includes(accentColor) ? Colors.White : Colors.Black,
          }
        : undefined
    }
    className={className}
    data-testid="text-intro"
    headline={headline || undefined}
    headlineProps={headlineProps}
    isCenteredDesktop={isCenteredDesktop}
    isCenteredMobile={isCenteredMobile}
    text={richText && <RichText elementMappers={elementMappers} richText={richText} />}
    textProps={textProps}
  >
    {button?.[0] && <AButton {...button[0]} variant={buttonVariant} />}
  </TextIntro>
);

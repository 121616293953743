import { FC, MouseEventHandler } from 'react';

import '@lichtblick/consent';
import {
  FooterBrandText,
  Footer as FooterComponent,
  FooterTeaser,
  NavigationItemProps,
  Text,
} from '@lichtblick/ui-components';

import { StyledLink, StyledText } from './Footer.styles';

import { ElementMappers, RichText } from '../../helpers/RichText';
import { useNavigationItems } from '../../hooks';
import { SettingsType } from '../../types';
import { MTeaserItem } from '../MTeaserItem';

const openUsercentricsSecondLayer: MouseEventHandler<HTMLAnchorElement> = (event) => {
  event.preventDefault();
  event.stopPropagation();
  window.__ucCmp?.showSecondLayer();
};

const attachUsercentricsClickHandler = (item: NavigationItemProps): NavigationItemProps => ({
  ...item,
  ...(item.href === '#uc-central-modal-show' ? { onClick: openUsercentricsSecondLayer } : {}),
  items: item.items?.map(attachUsercentricsClickHandler),
});

export type FooterSettings = Pick<
  SettingsType,
  | 'footerBrandText'
  | 'footerContactContactForm'
  | 'footerContactEmail'
  | 'footerContactHeadline'
  | 'footerContactOpeningHours'
  | 'footerContactPhone'
  | 'footerContactText'
  | 'footerPrimaryNavigation'
  | 'footerSecondaryNavigation'
  | 'footerTeaser'
>;

export type FooterProps = { footerSettings: FooterSettings; hasLogo?: boolean; isPrimaryNavigationHidden?: boolean };

const elementMappers: Partial<ElementMappers> = {
  p: ({ children }) => <StyledText>{children}</StyledText>,
  a: ({ children, href }) => (
    <Text size="M">
      <StyledLink href={href ?? '#'}>{children}</StyledLink>
    </Text>
  ),
};

export const Footer: FC<FooterProps> = ({
  footerSettings: {
    footerBrandText,
    footerContactContactForm,
    footerContactEmail,
    footerContactHeadline,
    footerContactOpeningHours,
    footerContactPhone,
    footerContactText,
    footerPrimaryNavigation,
    footerSecondaryNavigation,
    footerTeaser,
  },
  hasLogo,
  isPrimaryNavigationHidden,
}) => {
  const primaryNavigation = useNavigationItems(footerPrimaryNavigation ?? []);
  const secondaryNavigation = useNavigationItems(footerSecondaryNavigation ?? []);
  const secondaryNavigationWithUCClickHandler = secondaryNavigation.map(attachUsercentricsClickHandler);

  return (
    <FooterComponent
      contact={{
        headline: footerContactHeadline ?? undefined,
        text: footerContactText ?? undefined,
        formUrl: footerContactContactForm ?? undefined,
        email: footerContactEmail ?? undefined,
        phone: footerContactPhone ?? undefined,
        openingHours: footerContactOpeningHours ?? undefined,
      }}
      hasLogo={hasLogo}
      isPrimaryNavigationHidden={isPrimaryNavigationHidden}
      primaryNavigation={primaryNavigation}
      secondaryNavigation={secondaryNavigationWithUCClickHandler}
    >
      <FooterBrandText>
        {footerBrandText && <RichText elementMappers={elementMappers} richText={footerBrandText} />}
      </FooterBrandText>
      <FooterTeaser>{footerTeaser?.[0] && <MTeaserItem {...footerTeaser[0]} accentColor="dark-moss" />}</FooterTeaser>
    </FooterComponent>
  );
};

import { FC } from 'react';

import { WithMetaData } from '../../helpers/templateMapper';
import { useTemplateTracker } from '../../hooks';
import { StoryblokStoryType, TReferralType } from '../../types/storyblok';
import { Layout } from '../Layout';
import { OReferral } from '../OReferral';

export const TReferral: FC<WithMetaData<StoryblokStoryType<TReferralType>>> = ({
  content: {
    breadcrumbTitle,
    metaTitle,
    privacyButton,
    privacyHeadline,
    privacyLink,
    privacyText,
    trackingContentGroup,
    trackingContentGroup2,
    trackingContentGroup3,
    trackingPageArea,
  },
  parentPages,
  slug,
}) => {
  useTemplateTracker({
    contentGroup: trackingContentGroup,
    contentGroup2: trackingContentGroup2,
    contentGroup3: trackingContentGroup3,
    pageArea: trackingPageArea ?? '',
    pageType: 'referral',
    pageTitle: metaTitle ?? '',
  });

  return (
    <Layout breadcrumbTitle={breadcrumbTitle} parentPages={parentPages} slug={slug}>
      <OReferral
        privacyButton={privacyButton}
        privacyHeadline={privacyHeadline}
        privacyLink={privacyLink[0]}
        privacyText={privacyText}
      />
    </Layout>
  );
};

import { ChangeEvent } from 'react';

import { ToggleLabel, ToggleSwitch, ToggleSwitchCheckbox, ToggleWrapper } from './Toggle.styles';

import { TextSizeClass } from '../Text';

export type ToggleProps = {
  ariaLabel?: string;
  isChecked: boolean;
  isDisabled?: boolean;
  label: string;
  labelSize?: TextSizeClass;
  name: string;
  onClick: (e: ChangeEvent<HTMLInputElement>) => void;
  value?: string;
};

export const Toggle: React.FC<ToggleProps> = ({
  ariaLabel,
  isChecked = false,
  isDisabled = false,
  label,
  labelSize = 'S',
  name,
  onClick,
  value,
}) => (
  <ToggleWrapper $isDisabled={isDisabled} aria-label={ariaLabel}>
    <ToggleSwitchCheckbox
      checked={isChecked}
      data-checked={isChecked}
      disabled={isDisabled}
      name={name}
      onChange={onClick}
      value={value}
    />
    <ToggleSwitch $isChecked={isChecked} $isDisabled={isDisabled} />
    <ToggleLabel $isDisabled={isDisabled} isBold size={labelSize}>
      {label}
    </ToggleLabel>
  </ToggleWrapper>
);

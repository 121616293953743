import Script from 'next/script';
import { FC } from 'react';

import { CONSENT_STATUS_CHANGE } from '@lichtblick/consent';

type TrackingScriptProps = {
  gtmId?: string;
};

// see https://docs.usercentrics.com/#/consent-mode for more information
export const TrackingScript: FC<TrackingScriptProps> = ({ gtmId = 'GTM-WMPC837' }) => (
  <>
    <Script
      dangerouslySetInnerHTML={{
        __html: 'window.dataLayer = window.dataLayer || [];',
      }}
      data-testid="google-tag-manager_ensure-dataLayer-script"
      id="google-tag-manager_ensure-dataLayer-script"
    />

    <Script
      dangerouslySetInnerHTML={{
        __html: `function gtag() {
            dataLayer.push(arguments);
        }

        gtag("consent", "default", {
            ad_user_data: "denied",
            ad_personalization: "denied",
            ad_storage: "denied",
            analytics_storage: "denied",
            wait_for_update: 2000
        });

        gtag("set", "ads_data_redaction", true);`,
      }}
      data-testid="google-tag-manager_init-consent-gtag-script"
      id="google-tag-manager_init-consent-mode-script"
    />

    <Script
      dangerouslySetInnerHTML={{
        __html: `window.addEventListener('${CONSENT_STATUS_CHANGE}', function (e) {
            if( e.detail && e.detail.event == "consent_status") {
                var ucAnalyticsService = 'Google Analytics';
                var ucAdService = 'Google Ads Remarketing';
        
                if(e.detail.hasOwnProperty(ucAnalyticsService) && e.detail.hasOwnProperty(ucAdService))
                {
                    gtag("consent", "update", {
                        ad_storage: e.detail[ucAdService] ? 'granted':'denied',
                        ad_user_data: e.detail[ucAdService] ? 'granted':'denied',
                        ad_personalization: e.detail[ucAdService] ? 'granted':'denied',
                        analytics_storage: e.detail[ucAnalyticsService] ? 'granted':'denied'
                    });
                }
                else {            
                    if(e.detail.hasOwnProperty(ucAdService)) {
                        gtag("consent", "update", {
                            ad_storage: e.detail[ucAdService] ? 'granted':'denied',
                            ad_user_data: e.detail[ucAdService] ? 'granted':'denied',
                            ad_personalization: e.detail[ucAdService] ? 'granted':'denied' 
                        });
                    }            
                    if(e.detail.hasOwnProperty(ucAnalyticsService)) {
                      gtag("consent", "update", {
                            analytics_storage: e.detail[ucAnalyticsService] ? 'granted':'denied'
                        });
                    }
                }
            }
        });
      `,
      }}
      id="google-tag-manager_update-consent-mode-script"
    />

    <Script
      dangerouslySetInnerHTML={{
        __html: `(function(w, d, s, l, i) { w[l] = w[l] || []; w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' }); var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f); })(window, document, 'script', 'dataLayer',${JSON.stringify(
          gtmId,
        )});`,
      }}
      data-testid="google-tag-manager_load-script"
      data-usercentrics="Google Tag Manager"
      id="google-tag-manager_load-script"
      type="text/plain"
    />
    <noscript>
      <iframe
        aria-hidden
        height="0"
        src={`https://www.googletagmanager.com/ns.html?id=${encodeURIComponent(gtmId)}`}
        style={{ display: 'none', visibility: 'hidden' }}
        tabIndex={-1}
        title="Google Tag Manager"
        width="0"
      ></iframe>
    </noscript>
  </>
);

import { createContext } from 'react';

import { SettingsType } from '../types/storyblok';

export type Settings = Pick<
  SettingsType,
  | 'calculatorNoProductMaintenanceContent'
  | 'errorPage'
  | 'footerBrandText'
  | 'footerContactContactForm'
  | 'footerContactEmail'
  | 'footerContactHeadline'
  | 'footerContactOpeningHours'
  | 'footerContactPhone'
  | 'footerContactText'
  | 'footerPrimaryNavigation'
  | 'footerSecondaryNavigation'
  | 'footerTeaser'
  | 'maintenanceProducts'
  | 'metaNavigation'
  | 'pressDownloadButtonText'
  | 'pressDownloadHeadline'
  | 'pressRelatedArticlesHeadline'
  | 'pressTeaser'
  | 'primaryNavigation'
  | 'relatedArticlesTextIntro'
  | 'youTubePrivacyButton'
  | 'youTubePrivacyHeadline'
  | 'youTubePrivacyLink'
  | 'youTubePrivacyText'
  | 'calculatorElectricityUsageLimit'
  | 'calculatorGasUsageLimit'
>;

export const mapSettings = ({
  calculatorElectricityUsageLimit,
  calculatorGasUsageLimit,
  calculatorNoProductMaintenanceContent,
  errorPage,
  footerBrandText,
  footerContactContactForm,
  footerContactEmail,
  footerContactHeadline,
  footerContactOpeningHours,
  footerContactPhone,
  footerContactText,
  footerPrimaryNavigation,
  footerSecondaryNavigation,
  footerTeaser,
  maintenanceProducts,
  metaNavigation,
  pressDownloadButtonText,
  pressDownloadHeadline,
  pressRelatedArticlesHeadline,
  pressTeaser,
  primaryNavigation,
  relatedArticlesTextIntro,
  youTubePrivacyButton,
  youTubePrivacyHeadline,
  youTubePrivacyLink,
  youTubePrivacyText,
}: SettingsType): Settings => ({
  errorPage,
  footerBrandText,
  metaNavigation,
  primaryNavigation,
  youTubePrivacyButton,
  youTubePrivacyHeadline,
  youTubePrivacyLink,
  youTubePrivacyText,
  footerContactContactForm,
  footerContactEmail,
  footerContactHeadline,
  footerContactOpeningHours,
  footerContactPhone,
  footerContactText,
  footerPrimaryNavigation,
  footerSecondaryNavigation,
  footerTeaser,
  calculatorNoProductMaintenanceContent,
  maintenanceProducts,
  relatedArticlesTextIntro,
  pressDownloadButtonText,
  pressDownloadHeadline,
  pressRelatedArticlesHeadline,
  pressTeaser,
  calculatorElectricityUsageLimit,
  calculatorGasUsageLimit,
});

export const SettingsContext = createContext<Settings>(undefined as unknown as Settings);

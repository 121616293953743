import { FormEventHandler, useCallback, useEffect, useRef, useState } from 'react';

import { logger } from '@lichtblick/logger';
import { Spacing } from '@lichtblick/theme';
import { Box, BoxCTA, CheckboxInput, Text } from '@lichtblick/ui-components';

import { StyledKWKForm } from './KWKForm.styles';
import { Textfield } from './Textfield';

import { ElementMappers, RichText } from '../../helpers/RichText';
import { KwkFormType } from '../../types';
import { OInfoteaser } from '../OInfoteaser';
import { GridContainer, GridRow, GridColumn } from '../shared';

const richTextMapperOverride: Partial<ElementMappers> = {
  p: ({ children }) => (
    <Text hasSpacing renderAs="p" size="M">
      {children}
    </Text>
  ),
} as const;

export const KWKForm: React.FC<KwkFormType> = ({
  aknowledgement,
  infoTeaser,
  intro,
  optin,
  privacyNote,
  successTeaser,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [contractId, setContractId] = useState<string>('');
  const [meterId, setMeterId] = useState<string>('');
  const [email, setEmail] = useState<string>('');

  const [contractIdIncorrect, setContractIdIncorrect] = useState(false);
  const [counterNumberIncorrect, setCounterNumberIncorrect] = useState(false);

  const resetContractIdError = useCallback(() => setContractIdIncorrect(false), [setContractIdIncorrect]);
  const resetCounterNumberError = useCallback(() => setCounterNumberIncorrect(false), [setCounterNumberIncorrect]);

  const [missingOptin, setMissingOptin] = useState(false);

  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    setContractId(urlParams.get('contractId') ?? '');
    setMeterId(urlParams.get('meterId') ?? '');
    setEmail(urlParams.get('email') ?? '');
  }, [setContractId, setMeterId, setEmail]);

  const submit = useCallback<FormEventHandler<HTMLFormElement>>(
    async (e) => {
      e.preventDefault();
      setLoading(true);

      const formData = new FormData(e.currentTarget);

      const emailAddress = formData.get('email') as string;
      const counterNumber = formData.get('meterId') as string;
      const contractId = Number(formData.get('contractId'));

      const { sdk } = await import('../../api/lichtblick/graphqlClient');

      const response = await sdk.B2cAddLevyPrivilegeApplication({
        input: {
          contractId,
          counterNumber,
          emailAddress,
          optIn: true,
          optInText: optin,
        },
      });

      if (response.b2cAddLevyPrivilegeApplication.errors === null) {
        setSubmitted(true);

        logger.info('KWKForm: Successful submit', response);

        return;
      }

      if (response.b2cAddLevyPrivilegeApplication.errors?.find((e) => e.__typename === 'CounterNumberNotFoundError')) {
        setCounterNumberIncorrect(true);
      }

      if (
        response.b2cAddLevyPrivilegeApplication.errors?.find((e) => e.__typename === 'CustomerContractNotFoundError')
      ) {
        setContractIdIncorrect(true);
      }

      setLoading(false);
      logger.warn('KWKForm: Failed to submit', response);
    },
    [setSubmitted, setLoading, optin],
  );

  useEffect(() => {
    if (!submitted) {
      return;
    }

    containerRef.current?.scrollIntoView({ block: 'center' });
  }, [submitted, containerRef]);

  return submitted ? (
    <div ref={containerRef}>
      <OInfoteaser {...successTeaser[0]} />
    </div>
  ) : (
    <>
      {infoTeaser?.length === 1 && <OInfoteaser {...infoTeaser[0]} />}
      <Box pbd={Spacing.L} pbm={Spacing.M}>
        <GridContainer>
          <GridRow>
            <GridColumn $push={2} $width={8}>
              <StyledKWKForm onSubmit={submit}>
                <RichText elementMappers={richTextMapperOverride} richText={intro} />

                <Textfield
                  forcedError={contractIdIncorrect}
                  initialValue={contractId}
                  label="Vertragsnummer"
                  maxLength={8}
                  name="contractId"
                  pattern="\d{7,8}"
                  required
                  resetForcedError={resetContractIdError}
                />
                <Textfield
                  forcedError={counterNumberIncorrect}
                  initialValue={meterId}
                  label="Zählernummer"
                  maxLength={40}
                  name="meterId"
                  required
                  resetForcedError={resetCounterNumberError}
                  type="text"
                />
                <Textfield initialValue={email} label="E-Mail-Adresse" name="email" required type="email" />

                <div>
                  <RichText elementMappers={richTextMapperOverride} richText={aknowledgement} />
                </div>

                <CheckboxInput
                  hasError={missingOptin}
                  label={optin}
                  name="optin"
                  onChange={(e) => {
                    setMissingOptin(!e.currentTarget.validity.valid);
                  }}
                  onInvalid={(e) => {
                    e.preventDefault();
                    setMissingOptin(!e.currentTarget.validity.valid);
                  }}
                  required
                  statusLabel={missingOptin ? 'Hier fehlt noch eine Angabe' : undefined}
                />

                <BoxCTA as="button" isLoading={loading} type="submit">
                  Absenden
                </BoxCTA>
                <RichText richText={privacyNote} />
              </StyledKWKForm>
            </GridColumn>
          </GridRow>
        </GridContainer>
      </Box>
    </>
  );
};

import { FC } from 'react';

import { transient } from '@lichtblick/styled';

import { Root } from './Interferer.styles';

import { RichText } from '../../../../helpers/RichText';
import { RichTextDocType } from '../../../../types';

export type InterfererProps = {
  text: RichTextDocType;
};

export const Interferer: FC<InterfererProps> = ({ text, ...rest }) => (
  <Root {...transient(rest)}>
    <RichText richText={text} />
  </Root>
);

import { FC } from 'react';

import { assertExhaustive } from '../../helpers/assertExhaustive';
import { WithMetaData } from '../../helpers/templateMapper';
import { useTemplateTracker } from '../../hooks';
import { OTeaserGridArticlesType, StoryblokStoryType, TDetailType } from '../../types/storyblok';
import { BenefitCards } from '../eaas/BenefitCards';
import { CasesSlider } from '../eaas/CasesSlider/CasesSlider';
import { CompressedProductBundle } from '../eaas/CompressedProductBundle';
import { FourSteps } from '../eaas/FourSteps';
import { LeadForm } from '../eaas/LeadForm';
import { SoftFunnelEntry } from '../eaas/SoftFunnelEntry';
import { SolarProductBundle } from '../eaas/SolarProductBundle';
import { SolarSlider } from '../eaas/SolarSlider/SolarSlider';
import { SolarStage } from '../eaas/SolarStage/SolarStage';
import { StromWalletTextIntro } from '../eaas/StromWalletTextIntro';
import { TabsWithTextIntros, TextIntroWithTabs } from '../eaas/TabsWithTextIntros';
import { KWKForm } from '../KWKForm/KWKForm';
import { Layout } from '../Layout';
import { MNavigationSecondary } from '../MNavigationSecondary/MNavigationSecondary';
import { OAccordion } from '../OAccordion/OAccordion';
import { OAllInOneCalculator } from '../OAllInOneCalculator/OAllInOneCalculator';
import { OCarCalculator } from '../OCarCalculator';
import { OCarousel } from '../OCarousel/OCarousel';
import { OColumns } from '../OColumns/OColumns';
import { ODownload } from '../ODownload/ODownload';
import { OEAutoTariffComparison } from '../OEAutoTariffComparison';
import { OEditorial } from '../OEditorial/OEditorial';
import { OFactGrid } from '../OFactGrid/OFactGrid';
import { OFootnotes } from '../OFootnotes';
import { OForm } from '../OForm/OForm';
import { OHubSpotForm } from '../OHubSpotForm';
import { OIndex } from '../OIndex/OIndex';
import { OInfoteaser } from '../OInfoteaser/OInfoteaser';
import { OList } from '../OList/OList';
import { OLogos } from '../OLogos/OLogos';
import { OMedia } from '../OMedia/OMedia';
import { OProductComparison } from '../OProductComparison';
import { OProductOverview } from '../OProductOverview/OProductOverview';
import { OSnowflake } from '../OSnowflake/OSnowflake';
import { OStageArticle } from '../OStageArticle/OStageArticle';
import { OStageFull } from '../OStageFull/OStageFull';
import { OStageNarrow } from '../OStageNarrow/OStageNarrow';
import { OStageSplit } from '../OStageSplit/OStageSplit';
import { OStandoutText } from '../OStandoutText/OStandoutText';
import { OTable } from '../OTable/OTable';
import OTariffComparison from '../OTariffComparison/OTariffComparison';
import { OTeaserGrid, OTeaserGridProps } from '../OTeaserGrid/OTeaserGrid';
import { OTeaserGridArticles, TeaserGridArticlesProps } from '../OTeaserGridArticles';
import { OTeaserList } from '../OTeaserList/OTeaserList';
import { OText } from '../OText/OText';
import OWallboxConfigurator from '../OWallboxConfigurator/OWallboxConfigurator';

type ReplaceOTeaserGridArticlesType<T> = T extends OTeaserGridArticlesType ? TeaserGridArticlesProps : T;

export type TDetailProps = Omit<TDetailType, 'partials'> & {
  partials?: ReplaceOTeaserGridArticlesType<NonNullable<TDetailType['partials']>[number]>[];
};

const StageContent: FC<{ contentList: TDetailProps['stage'] }> = ({ contentList }) => {
  if (!contentList?.[0]) {
    return null;
  }

  const content = contentList[0];

  switch (content.component) {
    case 'o-stage-full':
      return <OStageFull {...content} />;
    case 'o-stage-narrow':
      return <OStageNarrow {...content} />;
    case 'o-stage-split':
      return <OStageSplit {...content} />;
    case 'o-stage-article':
      return <OStageArticle {...content} />;
    default:
      assertExhaustive(content);
  }
};

const PartialContent: FC<{ content: Exclude<TDetailProps['partials'], undefined>[number]; index: number }> = ({
  content,
  index,
}) => {
  if (!content) {
    return null;
  }

  switch (content.component) {
    case 'o-accordion':
      return <OAccordion {...content} />;
    case 'o-carousel':
      return <OCarousel {...content} />;
    case 'o-columns':
      return <OColumns {...content} />;
    case 'o-editorial':
      return <OEditorial {...content} />;
    case 'o-fact-grid':
      return <OFactGrid {...content} />;
    case 'o-form':
      return <OForm {...content} />;
    case 'o-list':
      return <OList {...content} />;
    case 'o-logos':
      return <OLogos {...content} />;
    case 'o-media':
      return <OMedia {...content} index={index} />;
    case 'o-snowflake':
      return <OSnowflake {...content} />;
    case 'o-standout-text':
      return <OStandoutText {...content} />;
    case 'o-tariff-comparison':
      return <OTariffComparison {...content} />;
    case 'o-teaser-grid':
      return <OTeaserGrid {...(content as OTeaserGridProps)} />; // type will become obsolete with rsc refactor
    case 'o-teaser-list':
      return <OTeaserList {...content} />;
    case 'o-text':
      return <OText {...content} />;
    case 'o-wallbox-configurator':
      return <OWallboxConfigurator {...content} />;
    case 'o-table':
      return <OTable {...content} />;
    case 'o-infoteaser':
      return <OInfoteaser {...content} />;
    case 'o-teaser-grid-articles':
      return <OTeaserGridArticles {...content} />;
    case 'o-all-in-one-calculator':
      return <OAllInOneCalculator {...content} />;
    case 'o-download':
      return <ODownload {...content} />;
    case 'o-index':
      return <OIndex {...content} />;
    case 'o-car-calculator':
      return <OCarCalculator {...content} />;
    case 'o-product-overview':
      return <OProductOverview {...content} />;
    case 'benefit-cards':
      return <BenefitCards {...content} />;
    case 'cases-slider':
      return <CasesSlider {...content} />;
    case 'compressed-product-bundle':
      return <CompressedProductBundle {...content} />;
    case 'four-steps':
      return <FourSteps {...content} />;
    case 'kwk-form':
      return <KWKForm {...content} />;
    case 'lead-form':
      return <LeadForm {...content} />;
    case 'o-eauto-tariff-comparison':
      return <OEAutoTariffComparison {...content} />;
    case 'o-hubspot-form':
      return <OHubSpotForm />;
    case 'o-product-comparison':
      return <OProductComparison {...content} />;
    case 'soft-funnel-entry':
      return <SoftFunnelEntry {...content} />;
    case 'solar-slider':
      return <SolarSlider {...content} />;
    case 'stromwallet-text-intro':
      return <StromWalletTextIntro {...content} />;
    case 'tabs-with-text-intros':
      return <TabsWithTextIntros {...content} />;
    case 'text-intro-with-tabs':
      return <TextIntroWithTabs {...content} />;
    case 'solar-product-bundle':
      return <SolarProductBundle {...content} />;
    case 'solar-stage':
      return <SolarStage {...content} />;
    default:
      assertExhaustive(content);
  }
};

export const TDetail: FC<WithMetaData<StoryblokStoryType<TDetailProps>>> = ({
  content: {
    breadcrumbTitle,
    footnotes,
    metaTitle,
    partials,
    secondaryNavigation,
    secondaryNavigationButtonIntroOnDesktop: isButtonIntroOnDesktop,
    stage,
    trackingContentGroup,
    trackingContentGroup2,
    trackingContentGroup3,
    trackingPageArea,
  },
  parentPages,
  slug,
}) => {
  useTemplateTracker({
    contentGroup: trackingContentGroup,
    contentGroup2: trackingContentGroup2,
    contentGroup3: trackingContentGroup3,
    pageArea: trackingPageArea ?? '',
    pageType: 'detail',
    pageTitle: metaTitle ?? '',
  });

  return (
    <Layout breadcrumbTitle={breadcrumbTitle} parentPages={parentPages} slug={slug}>
      {secondaryNavigation?.[0] && (
        <MNavigationSecondary {...secondaryNavigation[0]} isButtonIntroOnDesktop={isButtonIntroOnDesktop ?? false} />
      )}
      <StageContent contentList={stage} />
      {partials?.map((item, index) => item && <PartialContent content={item} index={index} key={item._uid} />)}
      {<OFootnotes footnotes={footnotes} />}
    </Layout>
  );
};

import React from 'react';

type Route = { absolute: string[]; catchAll: string[] };

export type Routes =
  | {
      externalRoutes: Route;
      internalRoutes?: Route;
    }
  | {
      externalRoutes?: Route;
      internalRoutes: Route;
    };

export const LinkContext = React.createContext<Routes | undefined>(undefined);

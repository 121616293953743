import { FC, useState } from 'react';

import { Icon } from '@lichtblick/icons';
import { AccentText, Text } from '@lichtblick/ui-components';

import { Caption, Card, CloseIcon, Description, Media, PlusIcon, Toggle } from './MFactItem.styles';

import { assertExhaustive } from '../../helpers/assertExhaustive';
import { ElementMappers, RichText } from '../../helpers/RichText';
import { MFactItemType } from '../../types/storyblok';
import { ColorKey, colorMap } from '../../utils';
import { AImage } from '../AImage/AImage';
import { AVideo } from '../AVideo/AVideo';

const elementMappers: Partial<ElementMappers> = {
  p: ({ children }) => <span>{children}</span>,
  b: ({ children }) => <AccentText size="L">{children}</AccentText>,
};

const MediaContent: FC<{ content: MFactItemType['media'] }> = ({ content: contentList }) => {
  if (!contentList?.[0]) {
    return null;
  }

  const content = contentList[0];

  switch (content.component) {
    case 'a-image':
      return <AImage {...content} />;
    case 'a-video':
      return <AVideo {...content} />;
    default:
      assertExhaustive(content);
  }
};

export const MFactItem: FC<MFactItemType & { backgroundColor: ColorKey }> = ({
  backgroundColor,
  caption,
  description,
  media,
}) => {
  const [isToggled, setIsToggled] = useState(false);

  return (
    <div data-testid="fact-item">
      <Card $backgroundColor={colorMap[backgroundColor]} $isToggled={isToggled}>
        <Toggle aria-label="Beschreibung anzeigen" onClick={() => setIsToggled(!isToggled)} role="button" />

        <PlusIcon>
          <Icon name="OpenIconS" />
        </PlusIcon>

        <Media $isToggled={isToggled}>
          <MediaContent content={media} />
        </Media>
        <Description $backgroundColor={colorMap[backgroundColor]} $isToggled={isToggled}>
          <CloseIcon>
            <Icon name="CloseIconS" />
          </CloseIcon>
          <Text>{description}</Text>
        </Description>
      </Card>
      <Caption size="M">
        <RichText elementMappers={elementMappers} richText={caption} />
      </Caption>
    </div>
  );
};

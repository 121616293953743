import { FC } from 'react';

import { generateLinks } from './MBreadcrumb.helpers';
import {
  Container,
  Crumb,
  CrumbLabel,
  Item,
  List,
  StyledIcon,
  StyledMBreadcrumbFooter,
  StyledMBreadcrumbTArticle,
} from './MBreadcrumb.styles';

import { GridColumn, GridContainer, GridRow } from '../shared';

import type { ParentPage } from '../../helpers/templateMapper';

export type MBreadcrumbProps = {
  breadcrumbTitle: string;
  mode: 'footer' | 'content';
  parentPages: ParentPage[];
  slug: string;
};

export const Modes = {
  footer: StyledMBreadcrumbFooter,
  content: StyledMBreadcrumbTArticle,
};

export const MBreadcrumb: FC<MBreadcrumbProps> = ({ breadcrumbTitle, mode, parentPages, slug }) => {
  const Component = Modes[mode];

  return (
    <Component>
      <GridContainer>
        <GridRow>
          <GridColumn $width={12}>
            <Container>
              <List>
                {generateLinks({ breadcrumbTitle, slug, parentPages }).map(({ title, url }) => (
                  <Item key={url}>
                    <Crumb href={url}>
                      <CrumbLabel>{title}</CrumbLabel>
                    </Crumb>
                    <StyledIcon />
                  </Item>
                ))}
              </List>
            </Container>
          </GridColumn>
        </GridRow>
      </GridContainer>
    </Component>
  );
};

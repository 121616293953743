import { FC, useCallback } from 'react';

import { InfoIconXs } from '@lichtblick/icons/svg/info/info-xs';
import { Spacing } from '@lichtblick/theme';
import { useTracker } from '@lichtblick/tracker';
import { BoxCTA, Container, StatusMessage, Textfield, Text } from '@lichtblick/ui-components';

import { usePostalCodeForm } from './SoftFunnel.helpers';
import { ButtonWrapper, Controls, FunnelForm, Root, StageCard, StyledSearchIcon } from './SoftFunnelEntry.styles';

import { SoftFunnelEntryType } from '../../../types';
import { getSpacing } from '../../../utils';

const message = `Leider kein Installationspartner in deiner Nähe gefunden. Werde benachrichtigt, sobald das SolarPaket \
in deiner Region verfügbar ist.`;

/**
 * exported separately to be used inline i.e. inside the solar stage
 */
export const SoftFunnelEntryCard: FC<Pick<SoftFunnelEntryType, 'theme' | 'title'>> = ({ theme, title }) => {
  const { hasNoPartnerAvailable, isLoading, onChangeHandler, onSubmit, postalCodeErrorMessage } = usePostalCodeForm();
  const buttonText = hasNoPartnerAvailable ? 'Benachrichtigt mich' : 'SolarAnlage berechnen';

  const { trackCustomEvent } = useTracker();

  const handleEvent = useCallback(() => {
    trackCustomEvent({
      eventName: 'eaas_soft_funnel_cta',
    });
  }, [trackCustomEvent]);

  return (
    <StageCard $theme={theme}>
      <FunnelForm onSubmit={onSubmit}>
        <Text isBold>{title || 'Wo soll Deine SolarAnlage installiert werden?'}</Text>
        <Controls>
          <Textfield
            leftSlot={<StyledSearchIcon />}
            name="postalCode"
            onChange={onChangeHandler}
            placeholder="Postleitzahl eingeben"
            required
            statusLabel={postalCodeErrorMessage}
            statusType={postalCodeErrorMessage ? 'error' : 'default'}
            type="text"
          />
          <ButtonWrapper>
            <BoxCTA
              as="button"
              isLoading={isLoading}
              isWide
              onClick={handleEvent}
              variant={hasNoPartnerAvailable ? 'secondary' : 'primary'}
            >
              {buttonText}
            </BoxCTA>
          </ButtonWrapper>
        </Controls>
        {hasNoPartnerAvailable && (
          <StatusMessage icon={<InfoIconXs />} message={message} status="action" type="outline" />
        )}
      </FunnelForm>
    </StageCard>
  );
};

export const SoftFunnelEntry: FC<SoftFunnelEntryType> = ({
  paddingBottomDesktop,
  paddingBottomMobile,
  paddingTopDesktop,
  paddingTopMobile,
  theme,
  ...props
}) => (
  <Root
    $theme={theme}
    pbd={getSpacing(paddingBottomDesktop) ?? Spacing.Xl}
    pbm={getSpacing(paddingBottomMobile) ?? Spacing.M}
    ptd={getSpacing(paddingTopDesktop) ?? Spacing.Xl}
    ptm={getSpacing(paddingTopMobile) ?? Spacing.M}
  >
    <Container>
      <SoftFunnelEntryCard theme={theme} {...props} />
    </Container>
  </Root>
);

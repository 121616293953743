import { FC, useState } from 'react';

import { Colors, Spacing } from '@lichtblick/theme';
import { Tabs, TabsType, TextIntro, Text } from '@lichtblick/ui-components';

import { FlexContainer, ImageWrapper, SvgWrapper } from './TabsWithTextIntros.styles';

import { ElementMappers, RichText } from '../../../helpers/RichText';
import { TabsWithTextIntrosType } from '../../../types';
import { getColor } from '../../../utils';
import { AImage } from '../../AImage';
import { isSvgImage } from '../../AImage/Picture.helpers';
import { StyledHeadline } from '../../MTextIntro/MTextIntro.styles';
import { AnchorMark } from '../../shared';
import { HalfSizedColumn, Section } from '../shared';

export const ANCHOR_TO_COMPARISON = 'Vergleich';

const elementMappers: Partial<ElementMappers> = {
  h1: ({ children }) => (
    <StyledHeadline renderAs="h1" size="S">
      {children}
    </StyledHeadline>
  ),
  h3: ({ children }) => (
    <StyledHeadline renderAs="h3" size="S">
      {children}
    </StyledHeadline>
  ),
  p: ({ children }) => (
    <Text renderAs="p" size="M">
      {children}
    </Text>
  ),
};

export const TabsWithTextIntros: FC<TabsWithTextIntrosType> = ({ tabsWithTextIntros, theme }) => {
  const [activeTab, setActiveTab] = useState<number>(0);

  const { chip, headline, richText } = tabsWithTextIntros[activeTab].textIntro[0];

  const chipProps = chip ? { label: chip } : undefined;

  const tabs = tabsWithTextIntros.map(({ _uid, component: _1, image, label, subline, textIntro: _2 }) => ({
    contentSlot: isSvgImage(image[0].imageMobile.filename) ? (
      <SvgWrapper>
        <AImage {...image[0]} />
      </SvgWrapper>
    ) : (
      <ImageWrapper>
        <AImage {...image[0]} />
      </ImageWrapper>
    ),
    headline: label,
    subline,
  })) as TabsType;

  return (
    <Section backgroundColor={getColor(theme)}>
      <AnchorMark anchorId={'Vergleich'} />
      <FlexContainer>
        <HalfSizedColumn>
          <TextIntro
            chipProps={chipProps}
            headline={headline}
            text={richText && <RichText elementMappers={elementMappers} richText={richText} />}
          />
        </HalfSizedColumn>
        <HalfSizedColumn>
          <Tabs
            backgroundColor={theme === 'white' ? Colors.BackgroundGray : Colors.White}
            gap={Spacing.S}
            onActiveTabChange={setActiveTab}
            tabs={tabs}
          />
        </HalfSizedColumn>
      </FlexContainer>
    </Section>
  );
};

import { FC } from 'react';

import { Spacing } from '@lichtblick/theme';
import { Box } from '@lichtblick/ui-components';

import { Grid, GridItem, TextIntroWrapper } from './OTeaserGrid.styles';

import { ElementMappers, RichText } from '../../helpers/RichText';
import { OTeaserGridType } from '../../types/storyblok';
import { getColor } from '../../utils';
import { MTeaserItem } from '../MTeaserItem';
import { MTextIntro } from '../MTextIntro';
import { UnmappedMTeaserItemProps } from '../OTeaserGridArticles';
import { GridColumn, GridContainer, GridRow } from '../shared';

export type OTeaserGridProps = Omit<OTeaserGridType, 'items'> & { items: UnmappedMTeaserItemProps[] };

const elementMappers: Partial<ElementMappers> = {
  a: ({ children }) => <>{children}</>,
  p: ({ children }) => <>{children}</>,
};

export const OTeaserGrid: FC<OTeaserGridProps> = ({ accent, anchorId, columns, items, textIntro, theme }) => {
  const columnCount = Number(columns) === 3 ? 3 : 2;

  if (items.length === 0) {
    return null;
  }

  return (
    <Box
      backgroundColor={getColor(theme)}
      data-testid="teaser-grid"
      id={anchorId ?? undefined}
      pyd={Spacing.Xl}
      pym={Spacing.L}
    >
      <GridContainer>
        {textIntro?.[0] && (
          <GridRow>
            <GridColumn $push={2} $width={8}>
              <TextIntroWrapper>
                <MTextIntro
                  {...textIntro[0]}
                  accentColor={getColor(accent)}
                  buttonVariant="link"
                  isCenteredDesktop
                  isCenteredMobile
                />
              </TextIntroWrapper>
            </GridColumn>
          </GridRow>
        )}

        <GridRow>
          <GridColumn $push={columnCount === 2 ? 1 : undefined} $width={columnCount === 3 ? 12 : 10}>
            <Grid $columns={columnCount}>
              {items.map((item) => (
                <GridItem key={item._uid}>
                  <MTeaserItem
                    {...item}
                    accentColor={accent ?? 'black'}
                    isTextOutside={false}
                    text={
                      typeof item.text === 'object' ? (
                        <RichText elementMappers={elementMappers} richText={item.text} />
                      ) : (
                        item.text
                      )
                    }
                  />
                </GridItem>
              ))}
            </Grid>
          </GridColumn>
        </GridRow>
      </GridContainer>
    </Box>
  );
};

import { FC, useContext, useMemo } from 'react';

import {
  createMaintenanceProductsConfig,
  MaintenanceProductsConfig,
} from '../../helpers/createMaintenanceProductsConfig';
import { SettingsContext } from '../../helpers/settingsContext';

type FormConfig = {
  api_gateway: {
    api_base_url: string | undefined;
    api_key: string | undefined;
  };
  graphql: {
    graphql_server: string | undefined;
  };
  maintenanceProducts: MaintenanceProductsConfig;
};

type FormConfigProps = {
  configId: string;
};

export const OFormConfig: FC<FormConfigProps> = ({ configId }) => {
  const { maintenanceProducts } = useContext(SettingsContext);

  const config: FormConfig = useMemo(
    () => ({
      api_gateway: { api_base_url: process.env.NEXT_PUBLIC_API_BASE_URL, api_key: process.env.NEXT_PUBLIC_API_KEY },
      graphql: { graphql_server: process.env.NEXT_PUBLIC_GRAPHQL_SERVER },
      maintenanceProducts: createMaintenanceProductsConfig(maintenanceProducts || []),
    }),
    [],
  );

  return (
    <div id={configId} style={{ display: 'none' }}>
      {JSON.stringify(config)}
    </div>
  );
};
